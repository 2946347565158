import React, { useState } from "react";
import AuditInventorySearch from "./audit-inventory-search.component";
import AuditInventoryGrid from "./audit-inventory-grid.component";
import * as auditInventoryServices from "./audit-inventory.services";
import * as GlobalService from "./../global_services";

import "./audit-inventory.scss";
interface Props {}
declare var window: any;
var $rootScope = window;
const selector = "AuditInventory";

const AuditInventoryHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [filterAreaToggle, setFilterAreaToggle] = useState(false);
    const [isAnimatedClassAdded, setIsAnimatedClassAdded] = useState(false);
    const [auditDataResponse, setAuditGridData] = useState([]);
    const [locationTypes, setLocationTypes] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [facUri, setFacUri] = useState("");
    const [facId, setFacId] = useState(0);
    const [closejobGif, setClosejobGif] = useState(false);

    const clickForFilterToggle = () => {
        setFilterAreaToggle(!filterAreaToggle);
        setIsAnimatedClassAdded(!isAnimatedClassAdded);
        setTimeout(() => {
            setIsAnimatedClassAdded(!isAnimatedClassAdded);
        }, 1000);
        resizeWindow();
    };
    const facilityChange = (facObj) => {
        if (facObj) {
            setFacUri(facObj.uri);
            setFacId(facObj.id);
            // kendo.ui.progress($("#" + selector + "Grid"), true);
            getAuditOptions(facObj.uri);
        } else {
            setFacUri("");
            setFacId(0);
            setAuditGridData([]);
        }
    };
    const getAuditOptions = (facUri) => {
        auditInventoryServices
            .auditOptions(facUri)
            .then((response) => {
                setAuditGridData(response.data.Audits);
                response.data.Users.unshift({
                    Id: "",
                    Name: "Unspecified",
                });
                setUserOptions(response.data.Users);
                response.data.LocationTypes.unshift({
                    Value: "",
                    Name: "All",
                });
                setLocationTypes(response.data.LocationTypes);
            })
            .finally(() => {
                kendo.ui.progress($("#" + selector + "Grid"), false);
            });
    };
    const searchApiCallback = () => {
        getAuditOptions(facUri);
    };

    const resizeWindow = () => {
        $(window).trigger("resize");
    };

    const closeJob = (auditUris) => {
        setClosejobGif(true);
        auditInventoryServices
            .closeJob(auditUris)
            .then((response) => {
                GlobalService.notification("success", window.translate("Label_Close_job_success"));
                getAuditOptions(facUri);
                setClosejobGif(false);
            })
            .catch(() => {
                setClosejobGif(false);
            });
    };

    return (
        <div
            id="audit-inventory-beta-holder-react"
            className={
                (filterAreaToggle ? " collapsedFilter " : " expandFilter ") +
                (isAnimatedClassAdded ? " animatedCollapse" : "")
            }
        >
            <AuditInventorySearch
                // sharedFilterObject={}
                selector={selector}
                windowId={windowId}
                onClickForFilterToggle={clickForFilterToggle}
                OnFacilitySelect={facilityChange}
                userOptions={userOptions}
                onApplyFilter={searchApiCallback}
            />
            <AuditInventoryGrid
                selector={selector}
                auditGridData={auditDataResponse}
                windowId={windowId}
                userOptions={userOptions}
                locationTypes={locationTypes}
                facId={facId}
                facUri={facUri}
                getAuditOptions={getAuditOptions}
                closeJob={closeJob}
                closejobGif={closejobGif}
            />
        </div>
    );
};
AuditInventoryHolder.propTypes = {};

AuditInventoryHolder.defaultProps = {};

export default React.memo(AuditInventoryHolder);
