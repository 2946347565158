import React, { Component } from "react";

import "./pack-and-ship-transaction-model.scss";

// Component
import WmsModel from "../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../components/WmsButton/WmsButton.jsx";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse.jsx";
import WmsDropdown from "../../components/WmsDropdown/WmsDropdown.jsx";
import WmsCheckBox from "../../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsInput from "../../components/WmsInput/WmsInput.jsx";
import WmsComboBox from "../../components/WmsComboBox/WmsComboBox.jsx";
import { EditAddressDetail } from "./edit-address-detail.component.jsx";
import VirtualGroupGrid from "../../components/VirtualGroupGrid/VirtualGroupGrid.jsx";
import { AvailableRates } from "./available-rates.component.jsx";
import { ArrayWms } from "../../../../assets/ts/ArrayWms.ts";
import { KendoGridWmsVirtual } from "../../../../assets/ts/KendoGridWmsVirtual.ts";
import * as GlobalService from "../../global_services";
import * as Scale from "../../scale";
import * as metaDataService from "../meta-data.service";
import * as PackAndShipGridConfig from "./pack-and-ship-grid-config.jsx";
import * as smallParcelService from ".././smallParcel.service.jsx";
import * as smallParcelSettingsServices from '../../smallParcelSettings/small-parcel-settings.services.jsx';

export class PackAndShipTransactionModel extends Component {
    constructor(props) {
        super(props);
        // Default we are assuming order don't have any pack item
        this.isAnyItemPacked = false;
        this.$rootScope = window;
        this.isPrintIconShaw = metaDataService.getPackAndShipAutoPrintInMetaData();
        this.translate = window.translate;
        window.packageArr = [];
        this.weightChanged = false;
        this.currentPackage = { PackageId: 0, PackageDefId: 0, Name: "" };
        this.previousSelectedPackage = {
            PackageId: 0,
            PackageDefId: 0,
            Name: "",
        };
        this.previousSelectedPackageArr = [];
        this.order = {};
        this.content = {};
        this.nonSkuContent = {};
        this.lastPackedSelectedIndex = 0;
        this.selectedItems = 0;
        this.showT = false;
        this.showQ = false;
        this.showS = false;
        this._isMounted = false;
        this.isSmallParcelGridRefresh = false;
        this.isIntegral = true; // you only add number value if it's true
        this.isDisabledPack = false;
        this.isSetActivePackage = true;
        this.packageIdForEdit = "";
        this.packageIdForDelete = "";
        this.qtyBaseOnUPC = 1;
        this.scanNotFoundMsg = this.translate("Label_Not_valid_Scan_Content");
        this.isAllowDuplicatedUPC = false;
        this.isCallAutoRateAPI = false;
        this.isCallAutoRateAPIMultiOrder = false;
        this.pkgCustomOption = {
            PackageDefId: 0,
            PackageId: 0,
            Name: this.translate("Label_Custom_Dimensions"),
            Description: this.translate("Label_Custom_Dimensions"),
            Barcode: "",
            IsDefault: false,
            LengthImperial: 0,
            WidthImperial: 0,
            HeightImperial: 0,
            WeightImperial: 0,
            LengthMetric: 0,
            WidthMetric: 0,
            HeightMetric: 0,
            WeightMetric: 0,
            AssignedCustomers: [],
        };

        this.isEnteredValue = false;
        this.isDuplicatePrintCalledFrom = "";
        this.scannedValueResArr = [];
        this.processAsOption = [];
        this.labelFormateOption = [
            { description: "PDF", value: "Pdf" },
            { description: "ZPL", value: "Zpl" },
        ];
        this.labelFormateOptionForHvp = [
            { description: "PDF", value: "Pdf" },
            { description: "ZPL", value: "Zpl" },
            { description: "PNG (Combo Label)", value: "Png" },
        ];
        this.returnLabelOptions = [
            { description: "Not Included", value: false },
            { description: "Included", value: true },
        ];

        let rights = GlobalService.GetRights();
        let isSParcelEnabled = false;
        let isSPackEnabled = false;
        if (rights.hasOwnProperty("Shipping.SmallParcelEnabled") && rights.hasOwnProperty("orders.manage.smartparcel")) {
            isSParcelEnabled = true;
        }

        if (rights.hasOwnProperty("Shipping.WarehousePackEnabled") && rights.hasOwnProperty("orders.edit.pack") && this.props.sharedSelectedOrderIds.length == 1) {
            isSPackEnabled = true;
        }

        this.isEnableProvider = rights.hasOwnProperty("Shipping.SmallParcelEnabled") && rights["Integrations.SmallParcelPartner"] == "ParcelApi";

        this.state = {
            selector: "packAndShipTransaction",
            gifShipBtn: false,
            gifPackNShipButton: false,
            isOpenDeleteConfirmModel: false,
            selectedPackageName: "",
            retailerOptions: [],
            cartonLabelOptions: [],
            transactionLabelOptions: [],
            retailerTemplate: 0,
            cartonLabel: 0,
            transactionLabel: metaDataService.getPackAndShipTransactionLabelInMetaData(),
            packAdnShipObj: {
                processAs: "Standard",
                labelFormat: "Pdf",
                returnLabel: false,
                comboLabelTemplate: "",
                comboLabelTemplateValue: "",
                SmallParcelPackSlip: "",
                SmallParcelAddressTemplate: "",
            },
            selectedPackage: "",
            addNumOfPackages: 1,
            packagesOptions: [],
            isOpenCustomPackageModel: false,
            customPackageObj: {},
            carrierOptions: [],
            menuOptions: [{ Reports: [] }],
            rights: rights,
            packAndShipOrderInfo: {},
            packAndShipContactString: "",
            smallPackGridData: [],
            isOpenAddQuantity: false,
            isOpenAddNonSkuQuantityModal: false,
            isOpenSerialPopup: false,
            enteredSerialNumber: "",
            isEnabledNextOnSerialPopup: false,
            serials: [],
            packSerialQty: 0,
            remainingQuantity: 0,
            itemQuantity: 0,
            nonSkuItemQuantity: 0,
            isOpenEditPackageDetailsModel: false,
            isOpenEditItemQtyModel: false,
            packedInSaveDisable: true,
            packGroupSaveDisable: true,
            selectPackedIn: "",
            editPackageOptions: [],
            editPackedInOptions: [],
            isOpenTrackByInfoMOdel: false,
            isOpenScanKeyNotAvailableModel: false,
            scanInputLoading: false,
            packCompleteLoading: false,
            isOpenConfirmDeleteAllPopup: false,
            invalidValue: false,
            disableDeleteAll: false,
            trackByModelInfo: [],
            editSelectedPackageDefId: "",
            editPackageWeight: "",
            editPackageHeight: "",
            editPackageWidth: "",
            editPackageLength: "",
            isCustomDimensionPackSelect: false,
            originalPackageWeight: "",
            scanGridKey: "",
            scanPackedQty: "",
            scanNonSkuPackedQty: "",
            isScanPackedNotAvailable: false,
            scanPackedLoading: false,
            deleteAllLoading: false,
            currentMeasurementStandardIsImperial: GlobalService.getMeasurementStandardFlag(this.props.facilityId),
            isPackNShipLoading: false,
            packNShipLoadingMsg: "",
            isQtyPopupScannedFocus: false,
            isSmartParcelEnabled: isSParcelEnabled,
            isSmartPackEnabled: isSPackEnabled,
            isAnyItemUnpacked: false,
            showAvailableRates: false,
            quantityRegx: /(^[0-9]+$|^$)/,
            isPackOrPackAndShipDisabled: false,
            packOrPackShipTooltip: "",
            scaleChangeWeight: 0,
            isDeviceConnect: false,
            isOneQtyPack: metaDataService.getPackAndShipOneQtyPackInMetaData(),
            selectUnpack: false,
            isPackSelectedBtnEnabled: false,
            packSelectedBtnTooltip: "",
            gridSortOptions: {},
            isShowEditContactIcon: false,
            isOpenEditAddressDetailModal: false,
            isShowEditCareerIcon: false,
            isOpenEditCareerDetailModal: false,
            isOpenDuplicatePrintingModel: false,
            carrier: this.translate("Label_None_Selected"),
            service: this.translate("Label_None_Selected"),
            reference1: "",
            reference2: "",
            reference3: "",
            packageContentDescription: "",
            tempCarrierAccountNickname: "",
            availableCarrierAccounts: "",
            optionsTemplateReports: [],
            optionsPackingReports: [],
            isIntegratedPackingSlips: false,
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        kendo.ui.progress($("#PackAndShipTransactionModel"), true);
        await GlobalService.GetCustomersAndFacility();
        // clear current package on componentDidMount
        this._clearCurrentPackage();
        if (this.props.sharedSelectedOrderIds.length == 1) {
            let orderId = this.props.sharedSelectedOrderIds[0];
            let facilityId = this.props.facilityId;
            smallParcelService.orderInfo(orderId).then((response) => {
                this.order = response.orderPackInfoResponse;
                if (this.order.PackageData.length > 0 && this.order.OrderPacked == false && !this.order.SmallParcelShipDate) {
                    this._setCurrentPackage(this.order.PackageData[this.order.PackageData.length - 1].PackageId);
                    // Which order we have open with have one or more packed item.
                    this.isAnyItemPacked = true;
                    // Call Order Pack Resumed Event
                    smallParcelService.orderPackEvent(orderId, 'OrderPackResumed').then((response) => {
                        console.log("OrderPackResumed Events", response);
                    });
                }

                var tmpOrderInfo = response.orderInfoResponse;
                this.setPackagesOptions(tmpOrderInfo, facilityId);
                var tmpContactString = this.generateContactString(tmpOrderInfo);

                // set transaction label option
                let tempTransactionLabelOptions = [];
                tempTransactionLabelOptions.push({
                    LabelTemplateId: 0,
                    Name: this.translate("Label_Not_Specified"),
                });
                var genericTransactionArr = []; // template name is 'Generic'
                var customGenericTransactionArr = []; // retailer is 'Transaction Label'
                var customTransactionArr = []; // retailer is 'Transaction Label'

                for (let tlIndex = 0; tlIndex < this.order.TransactionLabels.length; tlIndex++) {
                    const objTLabel = this.order.TransactionLabels[tlIndex];
                    let tlName = objTLabel.Retailer ? objTLabel.Retailer + " : " : "";
                    tlName = tlName + objTLabel.Name;
                    let newObjCLabel = {
                        Name: tlName,
                        LabelTemplateId: objTLabel.LabelTemplateId,
                    };
                    if (objTLabel.Name == "Generic" && objTLabel.Retailer == "Transaction Label") {
                        genericTransactionArr.push(newObjCLabel);
                    } else if (objTLabel.Retailer == "Transaction Label") {
                        customGenericTransactionArr.push(newObjCLabel);
                    } else {
                        customTransactionArr.push(newObjCLabel);
                    }
                }
                genericTransactionArr.sort(this.sortOptions);
                customGenericTransactionArr.sort(this.sortOptions);
                customTransactionArr.sort(this.sortOptions);
                tempTransactionLabelOptions = tempTransactionLabelOptions.concat(customGenericTransactionArr, genericTransactionArr, customTransactionArr);

                // set carton label option
                let tempCartonLabelOptions = [];
                tempCartonLabelOptions.push({
                    LabelTemplateId: 0,
                    Name: this.translate("Label_Not_Specified"),
                });

                var libraryUCC128CartonArr = []; // template name start with  Library-
                var customUCC128CartonArr = []; // no specific naming rule
                var genericCartonArr = []; // template name is Generic
                var customGenericCartonArr = []; // template retailer  is Carton label

                for (let clIndex = 0; clIndex < this.order.CartonLabels.length; clIndex++) {
                    const objCLabel = this.order.CartonLabels[clIndex];
                    let clName = objCLabel.Retailer ? objCLabel.Retailer + " : " : "";
                    clName = clName + objCLabel.Name;
                    let newObjCLabel = {
                        Name: clName,
                        LabelTemplateId: objCLabel.LabelTemplateId,
                    };
                    if (objCLabel.Name.indexOf("Library-") != -1) {
                        newObjCLabel.Name = newObjCLabel.Name.replace("Library-", "");
                        libraryUCC128CartonArr.push(newObjCLabel);
                    } else if (objCLabel.Name == "Generic" && objCLabel.Retailer == "Carton Label") {
                        genericCartonArr.push(newObjCLabel);
                    } else if (objCLabel.Retailer == "Carton Label") {
                        customGenericCartonArr.push(newObjCLabel);
                    } else {
                        customUCC128CartonArr.push(newObjCLabel);
                    }
                }

                libraryUCC128CartonArr.sort(this.sortOptions);
                genericCartonArr.sort(this.sortOptions);
                customGenericCartonArr.sort(this.sortOptions);
                customUCC128CartonArr.sort(this.sortOptions);

                if (customGenericCartonArr.length) {
                    customGenericCartonArr.push({
                        Name: "",
                        LabelTemplateId: "",
                        isSeparator: true,
                    });
                }
                if (genericCartonArr.length) {
                    genericCartonArr.push({
                        Name: "",
                        LabelTemplateId: "",
                        isSeparator: true,
                    });
                }
                if (customUCC128CartonArr.length) {
                    customUCC128CartonArr.push({
                        Name: "",
                        LabelTemplateId: "",
                        isSeparator: true,
                    });
                }

                tempCartonLabelOptions = tempCartonLabelOptions.concat(customGenericCartonArr, genericCartonArr, customUCC128CartonArr, libraryUCC128CartonArr);

                var packAdnShipObj = this.state.packAdnShipObj;
                if (tmpOrderInfo.smallParcelZplPrintDefault) {
                    packAdnShipObj.labelFormat = "Zpl";
                }
                if (tmpOrderInfo.autoPrintCartonLabelDefault) {
                    this.isPrintIconShaw = true;
                }
                if (tmpOrderInfo.smartPackScaleEnabled) {
                    if (window.self === window.top) {
                        console.log("Scale without iFrame");
                        Scale.connect();
                        document.addEventListener("scaleDeviceWeightChange", this.scaleDeviceWeightChange);
                        document.addEventListener("scaleConnectionChange", this.scaleConnectionChange);
                    } else {
                        console.log("Scale with iFrame");
                        window.top.postMessage(
                            {
                                event: "TRIGGER_SCALE_CONNECTION",
                                data: {},
                            },
                            "*"
                        );
                        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
                        var eventer = window[eventMethod];
                        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
                        var that = this;
                        eventer(messageEvent, function (e) {
                            //data you receive from parent is stored inside e.data
                            if (e.data.event === "scaleDeviceWeightChange") {
                                that.scaleDeviceWeightChange(e.data.data);
                            } else if (e.data.event === "scaleConnectionChange") {
                                that.scaleConnectionChange(e.data.data);
                            }
                        });
                    }
                }
                if (tmpOrderInfo.customerId) {
                    var customerObj = GlobalService.getCustomerById(tmpOrderInfo.customerId);
                    if (customerObj && customerObj.options && customerObj.options.allowDuplicateUPCs) {
                        this.isAllowDuplicatedUPC = true;
                    }
                }
                this.isDisabledPack = this.order.OrderPacked;
                this.isSetActivePackage = this.order.OrderPacked == true ? false : true;
                var transactionLabel = this.state.transactionLabel;
                var transactionAvailable = tempTransactionLabelOptions.find((x) => x.LabelTemplateId == this.state.transactionLabel);
                if (!transactionAvailable) {
                    transactionLabel = "";
                }

                this.setState({
                    packAndShipContactString: tmpContactString,
                    packAndShipOrderInfo: Object.assign({}, tmpOrderInfo, {
                        OrderId: orderId,
                    }),
                    cartonLabelOptions: tempCartonLabelOptions,
                    transactionLabelOptions: tempTransactionLabelOptions,
                    packAdnShipObj: packAdnShipObj,
                    transactionLabel: transactionLabel,
                });

                setTimeout(() => {
                    this.listGrid_dataBind();
                }, 500);
                this.callInitialPrintMenu(tmpOrderInfo.customerId);

                var self = this;
                $(document).off("click", ".edit-package");
                $(document).on("click", ".edit-package", function () {
                    self.packageIdForEdit = $(this).attr("package-id");
                    self.groupPackageEditPopupOpen();
                    return false;
                });
                $(document).off("click", ".remove-package");
                $(document).on("click", ".remove-package", function () {
                    self.packageIdForDelete = $(this).attr("package-id");
                    var pkgForDelete = self._getPackageFromOrder(self.packageIdForDelete);
                    self.setState({
                        isOpenDeleteConfirmModel: true,
                        selectedPackageName: pkgForDelete.Name,
                    });
                    return false;
                });
                $(document).off("click", ".remove-unassing-package");
                $(document).on("click", ".remove-unassing-package", function () {
                    var packageName = $(this).attr("unique-package-name");
                    self.removePackageFromArr(packageName);
                    if (self.previousSelectedPackageArr.length) {
                        var lastIndex = self.previousSelectedPackageArr.length - 1;
                        self.currentPackage = self.previousSelectedPackageArr[lastIndex];
                        self.activePackageIconChange();
                    } else if (self.order.PackageData.length > 0) {
                        self._setCurrentPackage(self.order.PackageData[self.order.PackageData.length - 1].PackageId);
                    } else {
                        self._clearCurrentPackage();
                    }
                    self.listGrid_dataBind();
                    return false;
                });

                $(document).off("click", ".print-package");
                $(document).on("click", ".print-package", function (e) {
                    var packageId = $(this).attr("package-id");
                    self.autoPrintForIndividualPackage(packageId);
                    return false;
                });
            })
            .finally(() => {});
        } else {
            if (this.props.isSameCustomerCallOrderApi) {
                let orderId = this.props.sharedSelectedOrderIds[0];
                smallParcelService.getOrderInfo(orderId).then((response) => {
                    var packAdnShipObj = this.state.packAdnShipObj;
                    if (response.smallParcelZplPrintDefault) {
                        packAdnShipObj.labelFormat = "Zpl";
                        this.setState({
                            packAdnShipObj: packAdnShipObj,
                        });
                    }
                });
            }
            this.callInitialPrintMenu();
            // Multiple Customer Settings
            smallParcelService.getCustomersSettings(this.props.sharedSelectedCustomerIds).then((response) => {
                if (response.data) {
                    _.map(response.data, (customer) => {
                        if (customer.Options && customer.Options.Packaging && customer.Options.Packaging.SmallParcelAutoRateShopOnShip) {
                            this.isCallAutoRateAPIMultiOrder = true;
                        }
                    });
                }
            });
        }
        let tmpProcessAsOption = [{ description: "Standard", value: "Standard" }];
        if (this.state.rights.hasOwnProperty("Shipping.SmallParcelHighVolumeProcessing")) {
            tmpProcessAsOption.push({
                description: "High-Volume",
                value: "High Volume",
            });
        }
        this.processAsOption = tmpProcessAsOption;
        var combobox = $("#PackAndShiAddaPackage").data("kendoComboBox");
        combobox.bind("filtering", function (e) {
            var filterValue = e.filter != undefined ? e.filter.value : "";
            e.preventDefault();
            this.dataSource.filter({
                logic: "or",
                filters: [
                    {
                        field: "Name",
                        operator: "contains",
                        value: filterValue,
                    },
                    {
                        field: "Barcode",
                        operator: "contains",
                        value: filterValue,
                    },
                ],
            });
        });

        $("#" + this.state.selector + "scanGridKey").keydown((e) => {
            if (e.keyCode == 13) {
                this.onScanValueEnter();
            }
        });

        $(document).off("keydown", "#" + this.state.selector + "scanPackedQty");
        $(document).on("keydown", "#" + this.state.selector + "scanPackedQty", (e) => {
            if (e.keyCode == 13) {
                this.onScanQtyPopupEnter();
            }
        });

        $(document).off("keydown", "#" + this.state.selector + "scanNonSkuPackedQty");
        $(document).on("keydown", "#" + this.state.selector + "scanNonSkuPackedQty", (e) => {
            if (e.keyCode == 13) {
                this.onScanNonSkuQtyPopupEnter();
            }
        });

        $(document).off("keydown", "#" + this.state.selector + "serialNumber");
        $(document).on("keydown", "#" + this.state.selector + "serialNumber", (e) => {
            if (e.keyCode == 13 && e.target.value) {
                this.onEnterSerialInput();
            }
        });
    }

    scaleDeviceWeightChange = (e) => {
        if (!this.state.isDeviceConnect) {
            this.setState({ isDeviceConnect: true });
        }
        console.log("scaleDeviceWeightChange Called", e);
        if (e.detail) {
            var weight = e.detail.weight;
            var measurementUnit = this.state.currentMeasurementStandardIsImperial ? "lbs" : "kg";
            if (measurementUnit != e.detail.unit) {
                if (e.detail.unit == "lbs" && measurementUnit == "kg") {
                    weight = (e.detail.weight / 2.2046).toFixed(4);
                } else if (e.detail.unit == "kg" && measurementUnit == "lbs") {
                    weight = (e.detail.weight * 2.2046).toFixed(4);
                } else if (e.detail.unit == "oz" && measurementUnit == "kg") {
                    weight = (e.detail.weight * 0.02834952).toFixed(4);
                } else if (e.detail.unit == "oz" && measurementUnit == "lbs") {
                    weight = (e.detail.weight / 16).toFixed(4);
                } else if (e.detail.unit == "g" && measurementUnit == "kg") {
                    weight = (e.detail.weight / 1000).toFixed(4);
                } else if (e.detail.unit == "g" && measurementUnit == "lbs") {
                    weight = (e.detail.weight / 453.59237).toFixed(4);
                }
            }

            if (this.state.isOpenEditPackageDetailsModel) {
                this.weightChanged = true;
                this.setState({
                    editPackageWeight: weight,
                    packGroupSaveDisable: false,
                });
            } else {
                this.setState({
                    scaleChangeWeight: weight,
                });
            }
        }
    };
    scaleConnectionChange = (e) => {
        this.setState(
            {
                isDeviceConnect: e.detail.connected,
            },
            () => {
                this.listGrid_dataBind();
            }
        );
    };
    sortOptions(first, second) {
        var firstLabelName = first.Name.toUpperCase(); // ignore upper and lowercase
        var secondLabelName = second.Name.toUpperCase(); // ignore upper and lowercase

        if (firstLabelName < secondLabelName) {
            return -1;
        }

        if (firstLabelName > secondLabelName) {
            return 1;
        }
        // names must be equal
        return 0;
    }
    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("scaleDeviceWeightChange", this.scaleDeviceWeightChange);
        document.removeEventListener("scaleConnectionChange", this.scaleConnectionChange);
    }
    setPackagesOptions = (tmpOrderInfo, facilityId) => {
        var customerId = tmpOrderInfo.customerId;
        smallParcelService
            .getCarriersAndCapacityTypes(customerId, facilityId)
            .then((response) => {
                // Get Carrier Accounts
                this.setState({ availableCarrierAccounts: response.CarrierAccounts.CarrierAccounts });

                var packageOptions = response.PackageDefs;

                let tmpShipEngineCarrierResponse = response.Carriers.Carriers;
                tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                    return obj.Name != "Buku" && obj.Name != "PitneyBowesExp";
                });
                if (!this.isEnableProvider) {
                    tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                        return obj.Name != "PitneyBowesStd";
                    });
                }

                var carrierOptions = tmpShipEngineCarrierResponse;

                packageOptions = _.orderBy(packageOptions, [(packageItem) => packageItem.Name.toLowerCase()], ["asc"]);
                packageOptions = _.orderBy(packageOptions, ["IsDefault"], ["desc"]);
                // packageOptions.find(function (e) {
                //     if (e.IsDefault) {
                //         e.Name = e.Name + " (default)";
                //     }
                // });
                packageOptions = packageOptions.filter(function (e) {
                    if (e.IsDefault) {
                        e.Name = e.Name + " (default)";
                    }
                    if (e.AssignedCustomers.length === 0 || e.AssignedCustomers.includes(customerId)) {
                        return true;
                    }
                });
                if (this.state.rights.hasOwnProperty("Shipping.SmartPackScaleEnabled")) {
                    packageOptions.push(this.pkgCustomOption);
                }
                var tmpCarrierServiceObj = this.getCarrierServiceName(carrierOptions, tmpOrderInfo.carrier, tmpOrderInfo.shipServiceCode);
                this.setState({
                    packagesOptions: packageOptions,
                    carrierOptions: carrierOptions,
                    carrier: tmpCarrierServiceObj.Carrier,
                    service: tmpCarrierServiceObj.Service,
                });
                // Set Carrier Account Nickname
                this.setCarrierAccountNickname(tmpOrderInfo.accountNumber);
            })
            .finally(() => {});
    };
    callInitialPrintMenu = (customerId = "") => {
        GlobalService.initialPrintMenu(customerId)
            .then((response) => {
                if (response) {
                    let reprintShippingLabelMenus = response.filter(function (e) {
                        return e.CategoryName == "Reprint Shipping Label";
                    });

                    // Check If Label Template Deleted Then Set Default Label Template Otherwise Set Small Parcel Setting Label Template
                    let tmpComboLabelTemplate = reprintShippingLabelMenus[0].Reports[0].Name;
                    let tmpComboLabelTemplateValue = reprintShippingLabelMenus[0].Reports[0].TemplatedRunnerKey;
                    if(this.state.packAndShipOrderInfo.smallParcelDefaultLabelTemplate != '') {
                        let selectedReport = reprintShippingLabelMenus[0].Reports.find((elem) => elem.Name == this.state.packAndShipOrderInfo.smallParcelDefaultLabelTemplate);
                        if(selectedReport != undefined) {
                            tmpComboLabelTemplate = selectedReport.Name;
                            tmpComboLabelTemplateValue = selectedReport.TemplatedRunnerKey;
                        }
                    }

                    // Set C2 Carton Label Template
                    if(this.state.packAndShipOrderInfo.smallParcelDefaultCartonLabel && this.state.packAndShipOrderInfo.smallParcelDefaultCartonLabel !== 'Not specified') {
                        let currentIndex = this.state.cartonLabelOptions.map(function(element) { return element.Name; }).indexOf(this.state.packAndShipOrderInfo.smallParcelDefaultCartonLabel);
                        let nextLabelTemplateId = this.state.cartonLabelOptions[currentIndex]?.LabelTemplateId;
                        this.setState({
                            cartonLabel: nextLabelTemplateId
                        });
                    }

                    // Get Packing Slip Report
                    if(this.state.packAndShipOrderInfo.smallParcelAddressTemplate && this.state.packAndShipOrderInfo.smallParcelPackSlip && this.state.packAndShipOrderInfo.smallParcelAddressTemplate !== "" && this.state.packAndShipOrderInfo.smallParcelPackSlip !== "") {
                        let param_payload = {
                            'customerId': customerId
                        }
                        smallParcelSettingsServices.getPackingSlipReports(param_payload).then((response) => {
                            if (response && response.status == 200) {
                                let responseData = response.data;
                                if(responseData.length >= 1) {
                                    let tempOptionsTemplateReports = responseData[0].Templates;
                                    tempOptionsTemplateReports.unshift({
                                        "Name": '',
                                        "Id": null
                                    });

                                    let tempOptionsPackingReports = [];
                                    tempOptionsPackingReports.push({
                                        "Name": '',
                                        "TemplatedRunner": null
                                    });
                                    for (let x = 0; x < responseData.length; x++) {
                                        if(responseData[x].Reports.length) {
                                            for (let y = 0; y < responseData[x].Reports.length; y++) {
                                                tempOptionsPackingReports.push(responseData[x].Reports[y]);
                                            }
                                        }
                                    }
                                    this.setState({
                                        optionsTemplateReports: tempOptionsTemplateReports,
                                        optionsPackingReports: tempOptionsPackingReports,
                                        isIntegratedPackingSlips: true
                                    });
                                }
                            }
                        });
                    }

                    this.setState({
                        menuOptions: reprintShippingLabelMenus,
                        packAdnShipObj: Object.assign(this.state.packAdnShipObj, {
                            comboLabelTemplate: tmpComboLabelTemplate,
                            comboLabelTemplateValue: tmpComboLabelTemplateValue,
                            SmallParcelAddressTemplate: this.state.packAndShipOrderInfo.smallParcelAddressTemplate,
                            SmallParcelPackSlip: this.state.packAndShipOrderInfo.smallParcelPackSlip,
                        }),
                    });
                }
            })
            .finally(() => {
                kendo.ui.progress($("#PackAndShipTransactionModel"), false);
            });
    };

    groupPackageEditPopupOpen = () => {
        let pkgForEdit = this._getPackageFromOrder(this.packageIdForEdit);
        this.setState(
            {
                editSelectedPackageDefId: pkgForEdit.PackageDefId,
                editPackageWeight: pkgForEdit.Weight,
                isCustomDimensionPackSelect: pkgForEdit.Name == this.translate("Label_Custom_Dimensions"),
                editPackageHeight: pkgForEdit.Height,
                editPackageWidth: pkgForEdit.Width,
                editPackageLength: pkgForEdit.Length,
                originalPackageWeight: pkgForEdit.Weight,
                packGroupSaveDisable: true,
                isOpenEditPackageDetailsModel: true,
                packageContentDescription: pkgForEdit.Description,
            },
            () => {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackageDetails"), true);
                smallParcelService
                    .getReferenceFields(pkgForEdit.PackageUri)
                    .then((response) => {
                        this.setState({
                            reference1: response.reference1,
                            reference2: response.reference2,
                            reference3: response.reference3,
                        });
                    })
                    .finally(() => {
                        kendo.ui.progress($("#" + this.props.windowId + "EditPackageDetails"), false);
                    });
            }
        );
        this.weightChanged = false;
    };

    onCloseDeleteConfirmationModel = () => {
        this.setState(
            {
                isOpenDeleteConfirmModel: false,
                selectedPackageName: "",
            },
            () => {
                this.setFocusOnScanAndSelectValue();
            }
        );
    };
    onCloseDeleteAllConfirmationModel = () => {
        this.setState(
            {
                isOpenConfirmDeleteAllPopup: false,
            },
            () => {
                this.setFocusOnScanAndSelectValue();
            }
        );
    };
    closePackGroup = () => {
        this.setState(
            {
                isOpenEditPackageDetailsModel: false,
            },
            () => {
                this.setFocusOnScanAndSelectValue();
            }
        );
    };
    handleEditPackageChange = (e) => {
        let pkg = this._getPackageFromOrder(this.packageIdForEdit);
        var packGroupSaveDisable = false;
        var tmpIsCustomDimensionPackSelect = this.state.isCustomDimensionPackSelect;
        if (e.target.name === "editSelectedPackageDefId") {
            tmpIsCustomDimensionPackSelect = false;
            if (pkg.PackageDefId == e.target.value && !this.weightChanged) {
                packGroupSaveDisable = true;
            }
            if (e.target.value == 0) {
                tmpIsCustomDimensionPackSelect = true;
            } else {
                var new_pkg = this.state.packagesOptions.find(function (p) {
                    return p.PackageDefId == e.target.value;
                });

                let editPackageHeight = this.state.currentMeasurementStandardIsImperial ? (new_pkg.HeightImperial ? new_pkg.HeightImperial + "" : "0") : new_pkg.HeightMetric ? new_pkg.HeightMetric + "" : "0";
                let editPackageWidth = this.state.currentMeasurementStandardIsImperial ? (new_pkg.WidthImperial ? new_pkg.WidthImperial + "" : "0") : new_pkg.WidthMetric ? new_pkg.WidthMetric + "" : "0";
                let editPackageLength = this.state.currentMeasurementStandardIsImperial ? (new_pkg.LengthImperial ? new_pkg.LengthImperial + "" : "0") : new_pkg.LengthMetric ? new_pkg.LengthMetric + "" : "0";

                this.setState({
                    editPackageHeight: editPackageHeight,
                    editPackageWidth: editPackageWidth,
                    editPackageLength: editPackageLength,
                });
            }
        }
        this.setState({
            [e.target.name]: e.target.value,
            packGroupSaveDisable: packGroupSaveDisable,
            isCustomDimensionPackSelect: tmpIsCustomDimensionPackSelect,
        });
        if (e.target.name === "editPackageWeight") {
            if (pkg.Weight != e.target.value) {
                this.weightChanged = true;
            }
        }
    };
    handleEditReferenceFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            packGroupSaveDisable: false,
        });
    };
    handleEditPackageContentDescriptionChange = (e) => {
        this.setState({
            packageContentDescription: e.target.value,
            packGroupSaveDisable: false,
        });
    };
    onResetIconClick = () => {
        this.setState({ editPackageWeight: this.state.originalPackageWeight });
    };
    closePackItem = () => {
        this.setState(
            {
                isOpenEditItemQtyModel: false,
            },
            () => {
                this.setFocusOnScanAndSelectValue();
            }
        );
    };
    deleteConfirmPackage = () => {
        var pkg = this._getPackageFromOrder(this.packageIdForDelete);
        if (pkg) {
            kendo.ui.progress($("#" + this.props.windowId + "confirmDeletePackage"), true);
            smallParcelService
            .deletePackage(pkg.PackageUri)
            .then((response) => {
                GlobalService.notification("success", this.translate("Label_Package_Remove_Success"));
                this.order.PackageData = this.order.PackageData.filter((p) => {
                    return p.PackageId != this.packageIdForDelete;
                });
                if (this.order.PackageData.length > 0) {
                    this._setCurrentPackage(this.order.PackageData[this.order.PackageData.length - 1].PackageId);
                    // Which order we have open with have one or more item remain into package.
                    this.isAnyItemPacked = true;
                } else {
                    this._clearCurrentPackage();
                    // Which order we have open and don't have item into package.
                    this.isAnyItemPacked = false;
                }
                this.setState({
                    isOpenDeleteConfirmModel: false,
                    selectedPackageName: "",
                    selectUnpack: false,
                    isPackSelectedBtnEnabled: false,
                });
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                this.listGrid_dataBind();
                this.isSmallParcelGridRefresh = true;
            })
            .finally(() => {
                kendo.ui.progress($("#" + this.props.windowId + "confirmDeletePackage"), false);
            });
        }
    };
    deleteAllConfirm = () => {
        this.setState({
            deleteAllLoading: true,
        });

        kendo.ui.progress($("#packAndShipTransactionGrid"), true);

        smallParcelService
            .deleteAllPackage(this.state.packAndShipOrderInfo.OrderId)
            .then( async (response) => {
                // Delete All Non Sku Packages
                if(this.order.Inserts) {
                    let orderId = this.props.sharedSelectedOrderIds[0];
                    for (const itemValue of this.order.Inserts) {
                        if(itemValue.InsertedQuantity && itemValue.PackageInsertIdentifier && itemValue.PackageInsertIdentifier.Id) {
                            try {
                                await smallParcelService.deletePackNonSkuInsertsAsync(orderId, itemValue.PackageInsertIdentifier.Id, 0);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }

                // Get Order Pack Info
                smallParcelService
                    .getOrderPackInfo(this.state.packAndShipOrderInfo.OrderId)
                    .then((response) => {
                        this.order = response;
                        // Which order we have open and no item into package.
                        this.isAnyItemPacked = false;
                        if (this.order.PackageData.length > 0) {
                            this._setCurrentPackage(this.order.PackageData[this.order.PackageData.length - 1].PackageId);
                            // Which order we have open with have one or more item into package.
                            this.isAnyItemPacked = true;
                        }
                        this._clearCurrentPackage();
                        // Enable pack button after package item change when order is already packed
                        this.isDisabledPack = false;
                        this.listGrid_dataBind();
                        this.isSmallParcelGridRefresh = true;
                    })
                    .finally(() => {
                        kendo.ui.progress($("#packAndShipTransactionGrid"), false);
                    });
            })
            .finally(() => {
                this.setState({
                    deleteAllLoading: false,
                    isOpenConfirmDeleteAllPopup: false,
                    selectUnpack: false,
                    isPackSelectedBtnEnabled: false,
                });
            });
    };
    _clearCurrentPackage = () => {
        this.currentPackage = {
            PackageId: 0,
            PackageDefId: 0,
            Name: "",
        };
    };
    listGrid_dataBind = () => {
        var items = [];
        if (!this.order.PackageData) {
            return;
        }
        var labelCount = {};
        var tmpPkgArr = _.cloneDeep(this.previousSelectedPackageArr);
        if (tmpPkgArr.length == 0) {
            labelCount = this.getLabelCounts(this.order.PackageData, this.currentPackage);
        } else if (tmpPkgArr.length) {
            labelCount = this.getLabelCounts(this.order.PackageData, tmpPkgArr);
        }

        window.packageArr = [];
        var index = 0;
        var disableDeleteAll = true;
        var tmpIsAnyItemUnpacked = false;
        var self = this;
        var weightLabel = this.state.currentMeasurementStandardIsImperial ? "lbs" : "kg";

        let tmpGridSortOptions = null;
        if (this.order.PackageData.length > 0) {
            tmpGridSortOptions = false;
            disableDeleteAll = false;
        } else {
            tmpGridSortOptions = {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true,
            };
            disableDeleteAll = true;
        }

        _.map(self.order.OrderItemData, function (value) {
            _.map(value.Allocations, function (subValue) {
                subValue.Remainder = subValue.Qty;
                subValue.PickRemainder = subValue.Qty;
                return subValue;
            });
            return self.order;
        });
        _.each(self.order.PackageData, (pacakgeValue) => {
            labelCount[pacakgeValue.Name].i++;
            var packageName = labelCount[pacakgeValue.Name].total > 1 ? pacakgeValue.Name + " (" + labelCount[pacakgeValue.Name].i + ")" : pacakgeValue.Name;
            if (window.packageArr.indexOf(packageName) == -1) {
                window.packageArr.push(packageName);
            }

            _.each(pacakgeValue.PackageContents, (contentValue) => {
                var item = self.order.OrderItemData.find(function (a) {
                    return a.OrderItemId == contentValue.OrderItemId;
                });
                var alloc = item.Allocations.find(function (b) {
                    return b.ReceiveItemId == contentValue.ReceiveItemId && b.Remainder >= contentValue.PackedQty;
                });

                if (!alloc) {
                    GlobalService.notification("error: ", "data mismatch please consolidate packages first and come back");
                    return;
                }
                alloc.Remainder -= contentValue.PackedQty;

                if (contentValue.PickedQty) {
                    alloc.PickRemainder -= contentValue.PickedQty;
                }
                index++;
                items.push({
                    DisplayQty: contentValue.PackedQty,
                    RowNumber: index,
                    PackageId: pacakgeValue.PackageId,
                    PackageName: packageName,
                    val: window.packageArr.indexOf(packageName),
                    PackageDefId: pacakgeValue.PackageDefId,
                    PackageUri: pacakgeValue.PackageUri,
                    PackageContentId: contentValue.PackageContentId,
                    Description: item.Description,
                    oiid: contentValue.OrderItemId,
                    riid: contentValue.ReceiveItemId,
                    PackedQty: contentValue.PackedQty,
                    SerialNumbers: contentValue.SerialNumbers,
                    PackageContentUri: contentValue.PackageContentUri,
                    Lot: contentValue.Lot,
                    Serial: contentValue.Serial,
                    OutBoundSerial: contentValue.SerialNumbers && contentValue.SerialNumbers.length ? contentValue.SerialNumbers.join(",") : "",
                    ExpirationDate: contentValue.ExpirationDate,
                    Sku: item.Sku,
                    PrimaryUOM: item.PrimaryUOM,
                    IsSerialize: item.IsSerialize,
                    Upc: item.Upc,
                    Qty: alloc.Qty,
                    Qualifier: alloc.Qualifier,
                    Location: alloc.Location,
                    MovableUnit: alloc.MovableUnit,
                    PickedQty: alloc.PickedQty,
                    PickUri: alloc.PickUri,
                    MispickUri: alloc.MispickUri,
                    UnpickUri: alloc.UnpickUri,
                    Remainder: 0,
                    PickRemainder: 0,
                    Dimensions: (pacakgeValue.Length ? pacakgeValue.Length + "x" : "0x") + (pacakgeValue.Width ? pacakgeValue.Width + "x" : "0x") + (pacakgeValue.Height ? pacakgeValue.Height : "0x"),
                    Weight: this.state.isDeviceConnect && pacakgeValue.PackageId == this.currentPackage.PackageId && this.isSetActivePackage ? "--" : (pacakgeValue.Weight ? pacakgeValue.Weight + "" : "0") + weightLabel,
                    Ucc128: pacakgeValue.Ucc128 ? pacakgeValue.Ucc128 : "",
                    CheckIcon: pacakgeValue.PackageId,
                    isPrintIconShaw: this.isPrintIconShaw,
                    isPrintIconDisable: (this.state.cartonLabel == 0 && this.state.transactionLabel == 0) || pacakgeValue.PackageId == 0,
                    isOrderAlreadyShipped: this.order.SmallParcelShipDate ? true : false,
                    IsInsert: item.IsInsert,
                    IsHazMat: item.IsHazMat,
                });
            });
        });

        _.each(tmpPkgArr, (packageItem, i) => {
            var packageName = "";

            labelCount[packageItem.Name].i++;
            if (packageItem.Name && labelCount[packageItem.Name] && labelCount[packageItem.Name].total > 1) {
                packageName = packageItem.Name + " (" + labelCount[packageItem.Name].i + ")";
            } else {
                packageName = packageItem.Name;
            }

            if (window.packageArr.indexOf(packageName) == -1) {
                window.packageArr.push(packageName);
            }
            index++;
            var tmpDimensions = this.state.currentMeasurementStandardIsImperial ? (packageItem.LengthImperial ? packageItem.LengthImperial + "x" : "0x") + (packageItem.WidthImperial ? packageItem.WidthImperial + "x" : "0x") + (packageItem.HeightImperial ? packageItem.HeightImperial : "0x") : (packageItem.LengthMetric ? packageItem.LengthMetric + "x" : "0x") + (packageItem.WidthMetric ? packageItem.WidthMetric + "x" : "0x") + (packageItem.HeightMetric ? packageItem.HeightMetric : "0x");
            items.push({
                DisplayQty: 0,
                RowNumber: index,
                PackageId: 0,
                PackageName: packageName,
                val: window.packageArr.indexOf(packageName),
                ExpirationDate: null,
                isShawZeroPackageId: true,
                Dimensions: tmpDimensions,
                Weight: this.state.isDeviceConnect && this.isSetActivePackage ? "--" : (this.state.currentMeasurementStandardIsImperial ? (packageItem.WeightImperial ? packageItem.WeightImperial + "" : "0") : packageItem.WeightMetric ? packageItem.WeightMetric + "" : "0") + weightLabel,
                CheckIcon: 0,
                Ucc128: packageItem.Ucc128 ? packageItem.Ucc128 : "0",
                isHideRow: true,
                isPrintIconShaw: true,
                isDeleteIconShawForUnAssingPkg: true,
                uniquePackageName: packageItem.uniquePackageName,
                isPrintIconDisable: false,
                isOrderAlreadyShipped: this.order.SmallParcelShipDate ? true : false,
            });
        });

        window.packageArr.push("");
        _.each(self.order.OrderItemData, (itemValue) => {
            _.each(itemValue.Allocations, (allocationValue) => {
                var packageName = "";
                if (window.packageArr.indexOf(packageName) == -1) {
                    window.packageArr.push(packageName);
                }

                if (allocationValue.Remainder > 0) {
                    index++;
                    var tmpDimensions = this.state.currentMeasurementStandardIsImperial ? (this.currentPackage.LengthImperial ? this.currentPackage.LengthImperial + "x" : "0x") + (this.currentPackage.WidthImperial ? this.currentPackage.WidthImperial + "x" : "0x") + (this.currentPackage.HeightImperial ? this.currentPackage.HeightImperial : "0x") : (this.currentPackage.LengthMetric ? this.currentPackage.LengthMetric + "x" : "0x") + (this.currentPackage.WidthMetric ? this.currentPackage.WidthMetric + "x" : "0x") + (this.currentPackage.HeightMetric ? this.currentPackage.HeightMetric : "0x");
                    var secondaryUpc = "";
                    var secondaryUpcPerUnitsRatio = 0;
                    var packageUpc = "";
                    var packageUpcPerUnitsRatio = 0;

                    // var isIntegral = GlobalService.getIsIntegeralFlagByType(itemValue.PrimaryUOM);
                    if (itemValue.SecondaryUnit) {
                        secondaryUpc = itemValue.SecondaryUnit.Upc;
                        // secondaryUpcPerUnitsRatio = isIntegral ? Math.ceil(itemValue.SecondaryUnit.InventoryUnitsPerUnit) : itemValue.SecondaryUnit.InventoryUnitsPerUnit;
                        secondaryUpcPerUnitsRatio = itemValue.SecondaryUnit.InventoryUnitsPerUnit;
                    }
                    if (itemValue.PackageUnit) {
                        packageUpc = itemValue.PackageUnit.Upc;
                        // packageUpcPerUnitsRatio = isIntegral ? Math.ceil(itemValue.PackageUnit.InventoryUnitsPerUnit) : itemValue.PackageUnit.InventoryUnitsPerUnit;
                        packageUpcPerUnitsRatio = itemValue.PackageUnit.InventoryUnitsPerUnit;
                    }

                    items.push({
                        DisplayQty: allocationValue.Remainder,
                        RowNumber: index,
                        PackageId: 0,
                        PackageName: packageName,
                        val: window.packageArr.indexOf(packageName),
                        PackageDefId: 0,
                        PackageUri: "",
                        Description: itemValue.Description,
                        PackageContentId: 0,
                        PackedQty: 0,

                        oiid: itemValue.OrderItemId,
                        Sku: itemValue.Sku,
                        PrimaryUOM: itemValue.PrimaryUOM,
                        IsSerialize: itemValue.IsSerialize,
                        Upc: itemValue.Upc,
                        secondaryUpc: secondaryUpc,
                        secondaryUpcPerUnitsRatio: secondaryUpcPerUnitsRatio,
                        packageUpc: packageUpc,
                        packageUpcPerUnitsRatio: packageUpcPerUnitsRatio,
                        PickTrackExpirationDate: itemValue.PickTrackExpirationDate,
                        PickTrackSerialNumber: itemValue.PickTrackSerialNumber,
                        OutboundMobileSerialization: itemValue.OutboundMobileSerialization,
                        TrackSerialNumber: itemValue.TrackSerialNumber,
                        isSerialUnique: itemValue.TrackSerialNumber == 2 || itemValue.TrackSerialNumber == 4 ? true : false,

                        riid: allocationValue.ReceiveItemId,
                        Qty: allocationValue.Qty,
                        Qualifier: allocationValue.Qualifier,
                        ExpirationDate: allocationValue.ExpirationDate,
                        Lot: allocationValue.Lot,
                        Serial: allocationValue.Serial,
                        OutBoundSerial: "",
                        Location: allocationValue.Location,
                        MovableUnit: allocationValue.MovableUnit,
                        PickedQty: allocationValue.PickedQty,
                        PickUri: allocationValue.PickUri,
                        MispickUri: allocationValue.MispickUri,
                        UnpickUri: allocationValue.UnpickUri,
                        Remainder: allocationValue.Remainder,
                        PickRemainder: allocationValue.PickRemainder,
                        isShawZeroPackageId: false,
                        Dimensions: tmpDimensions,
                        Weight: this.state.isDeviceConnect && this.isSetActivePackage ? "--" : (this.state.currentMeasurementStandardIsImperial ? (this.currentPackage.WeightImperial ? this.currentPackage.WeightImperial + "" : "0") : this.currentPackage.WeightMetric ? this.currentPackage.WeightMetric + "" : "0") + weightLabel,
                        Ucc128: this.currentPackage.Ucc128 ? this.currentPackage.Ucc128 : "0",
                        CheckIcon: 0,
                        isPrintIconShaw: this.isPrintIconShaw,
                        isPrintIconDisable: true,
                        isOrderAlreadyShipped: this.order.SmallParcelShipDate ? true : false,
                        IsInsert: itemValue.IsInsert,
                        IsHazMat: itemValue.IsHazMat,
                    });
                    if(!itemValue.IsInsert) {
                        tmpIsAnyItemUnpacked = true;
                    }
                }
            });
        });

        // Check Order Has Inserts
        if(self.order.Inserts) {
            _.each(self.order.Inserts, (itemValue) => {
                if(itemValue.PackageInsertIdentifier) {
                    index++;
                    items.push({
                        DisplayQty: itemValue.InsertedQuantity ? itemValue.InsertedQuantity : 0,
                        Barcode: itemValue.Barcode,
                        RowNumber: index,
                        PackageId: 0,
                        val: index,
                        Description: itemValue.PackageInsertIdentifier.Name,
                        InsertId: itemValue.PackageInsertIdentifier.Id,
                        InsertedQty:itemValue.InsertedQuantity ? itemValue.InsertedQuantity : 0,
                        PackageContentId: 0,
                        PackedQty: 0,
                        Sku: 'INSERTS',
                        ExpirationDate: null,
                        CheckIcon: itemValue.InsertedQuantity ? itemValue.InsertedQuantity : 0,
                    });
                }
            });
        }

        if (this.order.PackageData.length == 0 && !this.currentPackage.Name) {
            this.setFocusOnAddPackageAndSelectValue();
        } else {
            this.setFocusOnScanAndSelectValue();
        }
        this.previousSelectedPackageArr = tmpPkgArr;
        let isDisablePackObject = this.setEnableDisableForPack(tmpIsAnyItemUnpacked);
        this.setState({
            smallPackGridData: items,
            disableDeleteAll: disableDeleteAll,
            gridSortOptions: tmpGridSortOptions,
            isAnyItemUnpacked: tmpIsAnyItemUnpacked,
            isPackOrPackAndShipDisabled: isDisablePackObject.isPackOrPackAndShipDisabled,
            packOrPackShipTooltip: isDisablePackObject.packOrPackShipTooltip,
        });
        this.isSetActivePackage = true;
    };

    setEnableDisableForPack = (isAnyItemUnpacked) => {
        let isPackOrPackAndShipDisabled = false;
        let packOrPackShipTooltip = "";
        if (this.order.SmallParcelShipDate) {
            isPackOrPackAndShipDisabled = true;
            packOrPackShipTooltip = this.translate("Label_Already_Shipped_Tooltip");
        } else if (isAnyItemUnpacked) {
            isPackOrPackAndShipDisabled = true;
            packOrPackShipTooltip = this.translate("Label_Fully_Packed_Disable_Note");
        } else if (this.isDisabledPack) {
            isPackOrPackAndShipDisabled = true;
            packOrPackShipTooltip = this.translate("Label_Already_Packed");
        } else if (this.state.cartonLabel == 0 && this.state.transactionLabel == 0 && this.isPrintIconShaw == true) {
            isPackOrPackAndShipDisabled = true;
            packOrPackShipTooltip = this.translate("Label_Carton_Or_Transaction_Require_Tooltip");
        }
        return {
            isPackOrPackAndShipDisabled: isPackOrPackAndShipDisabled,
            packOrPackShipTooltip: packOrPackShipTooltip,
        };
    };

    getLabelCounts = (list, cur) => {
        var result = list.reduce((obj, p) => {
            if (p.PackageDefId == 0 && p.PackageUri) {
                p.Name = this.translate("Label_Custom_Dimensions");
            }
            if (!obj.hasOwnProperty(p.Name)) {
                obj[p.Name] = { i: 0, total: 1 };
            } else {
                obj[p.Name].total++;
            }
            return obj;
        }, {});

        //seed empty package
        if (Array.isArray(cur)) {
            for (let i = 0; i < cur.length; i++) {
                const element = cur[i];
                if (!result.hasOwnProperty(element.Name)) {
                    result[element.Name] = { i: 0, total: 1 };
                } else {
                    result[element.Name].total++;
                }
            }
        } else if (cur.Name && cur.PackageId == 0) {
            if (!result.hasOwnProperty(cur.Name)) {
                result[cur.Name] = { i: 0, total: 1 };
            } else {
                result[cur.Name].total++;
            }
        }
        return result;
    };
    onChangeFunc = (e) => {
        this.setState({
            packAdnShipObj: Object.assign(this.state.packAdnShipObj, {
                [e.target.name]: e.target.value,
            }),
        });
    };

    onScanValueEnter = () => {

        var scanValueNormal = this.state.scanGridKey.trim();
        if (!scanValueNormal) {
            return false;
        }

        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
        var data = gridRef.dataSource.data();

        var isBarcodeActionPerformed = this.scannedBarcodeAction(scanValueNormal);
        if (isBarcodeActionPerformed) {
            this.setState({ scanGridKey: "" });
            return true;
        }

        var scanValue = scanValueNormal.toUpperCase();
        var isSkuFoundButPacked = false;
        var res = $.grep(data, (d) => {
            var tmpSku = d.Sku ? d.Sku.toUpperCase() : "";
            var tmpUpc = d.Upc ? d.Upc.toUpperCase() : "";
            var tmpSecondaryUpc = d.secondaryUpc ? d.secondaryUpc.toUpperCase() : "";
            var tmpPackageUpc = d.packageUpc ? d.packageUpc.toUpperCase() : "";
            var tmpMovableUnit = d.MovableUnit ? d.MovableUnit.toUpperCase() : "";
            var tmpDescription = d.Description ? d.Description.toUpperCase() : "";
            var tmpBarcode = d.Barcode ? d.Barcode.toUpperCase() : "";

            // Check Is Non Sku Package items
            if(tmpSku == "INSERTS" && (tmpDescription == scanValue || tmpBarcode == scanValue)) {
                return true;
            }

            if (d.PackageId > 0 && (tmpSku == scanValue || tmpMovableUnit == scanValue || (this.isAllowDuplicatedUPC == false && (tmpUpc == scanValue || tmpSecondaryUpc == scanValue || tmpPackageUpc == scanValue)))) {
                isSkuFoundButPacked = true;
            }
            return d.PackageId == 0 && (tmpSku == scanValue || tmpMovableUnit == scanValue || (this.isAllowDuplicatedUPC == false && (tmpUpc == scanValue || tmpSecondaryUpc == scanValue || tmpPackageUpc == scanValue)));
        });
        if (res.length > 0) {
            // Check Is Non Sku Inserts
            if(res[0].Sku == "INSERTS") {
                this.clickRowAndOpenQtyPop(res[0]);
            } else {
                var tmpSecondaryUpc = res[0].secondaryUpc ? res[0].secondaryUpc.toUpperCase() : "";
                var tmpPackageUpc = res[0].packageUpc ? res[0].packageUpc.toUpperCase() : "";
                this.qtyBaseOnUPC = 1;
                if (tmpSecondaryUpc == scanValue) {
                    this.qtyBaseOnUPC = res[0].secondaryUpcPerUnitsRatio;
                } else if (tmpPackageUpc == scanValue) {
                    this.qtyBaseOnUPC = res[0].packageUpcPerUnitsRatio;
                }
                this.clickRowAndOpenQtyPop(res[0]);
            }
        } else if (res.length === 0 && isSkuFoundButPacked === true) {
            this.scanNotFoundMsg = this.translate("Label_Fully_Packed_valid_Scan_Content");
            this.showScanNotFound();
        } else {
            if (this.scannedValueResArr[scanValueNormal]) {
                this.handleMainModalBarcodeScanResult(scanValueNormal);
            } else {
                this.setState({ scanInputLoading: true });
                let customerId = this.state.packAndShipOrderInfo.customerId;

                smallParcelService
                    .lineScanResolver(customerId, scanValueNormal)
                    .then((response) => {
                        this.scannedValueResArr[scanValueNormal] = response.data;
                        this.handleMainModalBarcodeScanResult(scanValueNormal);
                    })
                    .finally(() => {
                        this.setState({
                            scanInputLoading: false,
                        });
                    });
            }
        }
    };

    showScanNotFound = () => {
        this.setState({
            isOpenScanKeyNotAvailableModel: true,
        });
    };
    onCloseScanKeyNotAvailableModel = () => {
        this.setState({ isOpenScanKeyNotAvailableModel: false });
        this.setFocusOnScanAndSelectValue();
        this.scanNotFoundMsg = this.translate("Label_Not_valid_Scan_Content");
    };

    clickRowAndOpenQtyPop = (res) => {
        this.isEnteredValue = true;
        console.log("clickRowAndOpenQtyPop : ", res);
        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
        gridRef.clearSelection();
        gridRef.select($("tr[data-uid='" + res.uid + "']"));
        this.onGridSelection(res);
    };

    handleMainModalBarcodeScanResult = (scannedValue) => {
        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");

        let scannedResult = this.scannedValueResArr[scannedValue];
        if (scannedResult.SKU || scannedResult.UPC) {
            var isSkuFoundButPacked = false;
            var res = $.grep(gridRef.dataSource.data(), (d) => {
                // console.log("handleMainModalBarcodeScanResult grepLoop : " + d.PackageId + "   d.Sku: " + d.Sku + "   scannedResult.SKU:" + scannedResult.SKU);
                if (d.PackageId > 0 && ((scannedResult.SKU ? d.Sku.toUpperCase() == scannedResult.SKU.toUpperCase() : false) || (this.isAllowDuplicatedUPC == false && scannedResult.UPC && d.Upc ? d.Upc.toUpperCase() == scannedResult.UPC.toUpperCase() : false))) {
                    isSkuFoundButPacked = true;
                }
                return d.PackageId == 0 && ((scannedResult.SKU ? d.Sku.toUpperCase() == scannedResult.SKU.toUpperCase() : false) || (this.isAllowDuplicatedUPC == false && scannedResult.UPC && d.Upc ? d.Upc.toUpperCase() == scannedResult.UPC.toUpperCase() : false));
            });
            // console.log("handleMainModalBarcodeScanResult : res ", res);
            // console.log("handleMainModalBarcodeScanResult : isSkuFoundButPacked: " + isSkuFoundButPacked);
            if (res.length > 0) {
                this.qtyBaseOnUPC = 1;
                this.clickRowAndOpenQtyPop(res[0]);
            } else if (res.length === 0 && isSkuFoundButPacked === true) {
                this.scanNotFoundMsg = this.translate("Label_Fully_Packed_valid_Scan_Content");
                this.showScanNotFound();
            } else {
                this.scanNotFoundMsg = this.translate("Label_Not_valid_Scan_Content");
                this.showScanNotFound();
            }
        } else {
            this.scanNotFoundMsg = this.translate("Label_Not_valid_Scan_Content");
            this.showScanNotFound();
        }
    };

    onClickPackSelectedItem = () => {
        this.lastPackedSelectedIndex = 0;
        this.selectedItems = [];

        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
        var rows = gridRef.select();

        $.map(rows, (row) => {
            this.selectedItems.push(_.cloneDeep(gridRef.dataItem(row)));
        });
        // this.selectedItems = rows.map(function(row) {
        //     return gridRef.dataItem(row)
        // });
        // console.log("onClickPackSelectedItem this.selectedItems : ", this.selectedItems);
        this.checkNextItemToPackSelected();
    };

    checkNextItemToPackSelected = (fromCalled = "") => {
        // console.log("checkNextItemToPackSelected this.selectedItems : ", this.selectedItems, this.lastPackedSelectedIndex, fromCalled, this.content.PackedQty);
        var isSerialNumberRequired = false;
        for (let i = this.lastPackedSelectedIndex; i < this.selectedItems.length; i++) {
            const selectedItem = this.selectedItems[i];
            if (selectedItem.OutboundMobileSerialization > 0) {
                this.lastPackedSelectedIndex = i + 1;
                if (fromCalled !== "qtyNext" && fromCalled !== "_packContent") {
                    this.setState({
                        isEnabledNextOnSerialPopup: false,
                    });
                    this.content = selectedItem;
                    var packagedQty = this.qtyBaseOnUPC;
                    if (this.isIntegral) {
                        packagedQty = Math.ceil(this.qtyBaseOnUPC);
                    }
                    if (this.content.Remainder > 1 || !this.isIntegral) {
                        if (this.state.isOneQtyPack) {
                            this.content.PackedQty = this.qtyBaseOnUPC < this.content.Remainder ? packagedQty : this.content.Remainder;
                        } else {
                            this.content.PackedQty = this.content.Remainder;
                        }
                    } else {
                        this.content.PackedQty = 1;
                    }
                }
                // GlobalService.notification("error", selectedItem.Sku + ' item has require serial number, please try again after deselect');
                isSerialNumberRequired = true;
                break;
            }
        }

        if (isSerialNumberRequired) {
            this._serial();
        } else if (isSerialNumberRequired == false) {
            if (this.state.isOpenSerialPopup) {
                this.isSerialNumberDisableToAddNew = false;
                this._add(this.props.windowId + "SerialPopup");
            } else {
                this._add("PackAndShipTransactionModel");
            }
        }
    };

    onDeleteAllPackage = () => {
        this.setState({
            isOpenConfirmDeleteAllPopup: true,
        });
    };

    setFocusOnScanAndSelectValueQtyPopup = () => {
        // clear scan value after increment quantity or show record not found message
        this.setState({
            scanPackedQty: "",
            isQtyPopupScanDetected: false,
        });
        setTimeout(() => {
            if (this.refs.scanPackedQty) {
                this.refs.scanPackedQty.focus();
            }
        }, 0);
    };

    setFocusOnScanNonSkuAndSelectValueQtyPopup = () => {
        // clear scan value after increment quantity or show record not found message
        this.setState({
            scanNonSkuPackedQty: "",
            isQtyPopupScanDetected: false,
        });
        setTimeout(() => {
            if (this.refs.scanNonSkuPackedQty) {
                this.refs.scanNonSkuPackedQty.focus();
            }
        }, 0);
    };

    onScanQtyPopupEnter = () => {
        this.setState({
            isQtyPopupScanDetected: true,
        });
        var scanPackedQtyValue = this.state.scanPackedQty;
        this.qtyBaseOnUPC = 1;
        if (this.content.secondaryUpc == scanPackedQtyValue) {
            this.qtyBaseOnUPC = this.content.secondaryUpcPerUnitsRatio;
        } else if (this.content.packageUpc == scanPackedQtyValue) {
            this.qtyBaseOnUPC = this.content.packageUpcPerUnitsRatio;
        }
        if (this.content.Sku == scanPackedQtyValue || this.content.Upc == scanPackedQtyValue || this.content.MovableUnit == scanPackedQtyValue || this.content.secondaryUpc == scanPackedQtyValue || this.content.packageUpc == scanPackedQtyValue) {
            this.onQtyIncrement();
            this.setFocusOnScanAndSelectValueQtyPopup();
        } else {
            if (this.scannedValueResArr[scanPackedQtyValue]) {
                this.handleQtyPopupBarcodeScannedResult(scanPackedQtyValue);
            } else {
                this.setState({ scanPackedLoading: true });
                let customerId = this.state.packAndShipOrderInfo.customerId;

                smallParcelService
                    .lineScanResolver(customerId, scanPackedQtyValue)
                    .then((response) => {
                        this.scannedValueResArr[scanPackedQtyValue] = response.data;
                        this.handleQtyPopupBarcodeScannedResult(scanPackedQtyValue);
                    })
                    .finally(() => {
                        this.setState({
                            scanPackedLoading: false,
                        });
                    });
            }
        }
    };

    onScanNonSkuQtyPopupEnter = () => {
        this.setState({
            isQtyPopupScanDetected: true,
        });
        this.onNonSkuQtyIncrement();
    };

    handleQtyPopupBarcodeScannedResult = (scannedValue) => {
        var tmpIsScanPackedNotAvailable = true;
        let scannedResult = this.scannedValueResArr[scannedValue];

        if (scannedResult.SKU || scannedResult.UPC) {
            if ((scannedResult.SKU && scannedResult.SKU == this.content.Sku) || (scannedResult.UPC && scannedResult.UPC == this.content.Upc)) {
                this.qtyBaseOnUPC = 1;
                this.onQtyIncrement();
                tmpIsScanPackedNotAvailable = false;
            }
        }
        this.setState({
            isScanPackedNotAvailable: tmpIsScanPackedNotAvailable,
        });

        if (tmpIsScanPackedNotAvailable) {
            setTimeout(() => {
                this.setState({ isScanPackedNotAvailable: false });
            }, 3000);
        }
        this.setFocusOnScanAndSelectValueQtyPopup();
    };

    onPackageChange = (e) => {
        var packAndShipCombobox = $("#PackAndShiAddaPackage").data("kendoComboBox");
        let packageDefId = "";
        // let pkg = this.getPackageByPackageDefIdFromPackageOptions(e.target.value);
        var isBarcodeActionPerformed = this.scannedBarcodeAction(packAndShipCombobox.text());
        if (isBarcodeActionPerformed) {
            packAndShipCombobox.text("");
            return true;
        }
        if (e.target.value == 0 && e.target.selectedIndex !== -1) {
            this.setState({
                isOpenCustomPackageModel: true,
                customPackageObj: {},
            });
            packAndShipCombobox.text("");
            return true;
        }
        if (e.target.value && e.target.selectedIndex == -1) {
            packAndShipCombobox.select(function (dataItem) {
                return dataItem.Name == e.target.value || dataItem.Barcode == e.target.value;
            });
            packageDefId = packAndShipCombobox.value();
        } else {
            packageDefId = e.target.value;
        }

        console.log("onPackageChange: ", e.target.value, e.target.selectedIndex, packageDefId);

        if (packAndShipCombobox) {
            packAndShipCombobox.value("");
        }
        if (packageDefId) {
            this.previousSelectedPackage = this.currentPackage;
            this.currentPackage = this.getPackageByPackageDefIdFromPackageOptions(packageDefId);
            this.currentPackage.PackageId = 0;
            this.currentPackage.isShawZeroPackageId = true;
            var tmpUniquePackageName = this.getUniquePackageName(this.currentPackage.Name);
            this.currentPackage.uniquePackageName = tmpUniquePackageName;

            for (let i = 0; i < this.state.addNumOfPackages; i++) {
                var tmpCloneCurrentPkg = _.cloneDeep(this.currentPackage);
                tmpCloneCurrentPkg.uniquePackageName = this.getUniquePackageName(this.currentPackage.Name);
                this.previousSelectedPackageArr.push(tmpCloneCurrentPkg);
                this.currentPackage = tmpCloneCurrentPkg;
            }
            this.listGrid_dataBind();
            this.setFocusOnScanAndSelectValue();
        } else if (e.target.value) {
            GlobalService.notification("error", "Scanned package not found");
        }
    };
    removePackageFromArr = (package_name) => {
        _.remove(this.previousSelectedPackageArr, {
            uniquePackageName: package_name,
        });
    };
    scannedBarcodeAction = (scannedValue) => {
        // console.log("scannedValue :",scannedValue);
        if (scannedValue == "Pack") {
            if (this.state.isPackOrPackAndShipDisabled) {
                GlobalService.notification("error", this.state.packOrPackShipTooltip);
            } else {
                this.onPackClick();
            }
            return true;
        } else if (scannedValue == "Pack and Ship") {
            if (this.state.isPackOrPackAndShipDisabled) {
                GlobalService.notification("error", this.state.packOrPackShipTooltip);
            } else {
                this.onPackNShipClick();
            }
            return true;
        } else if (scannedValue == "Print Carton Label") {
            if (!this.currentPackage.Name) {
                GlobalService.notification("error", this.translate("Label_No_Package_Selected"));
            } else if (!this.currentPackage.PackageId) {
                GlobalService.notification("error", this.translate("Label_Print_Package_Error"));
            } else if (this.state.cartonLabel == 0) {
                GlobalService.notification("error", this.translate("Label_Carton_Require_Tooltip"));
            } else {
                this.autoPrintForIndividualPackage(this.currentPackage.PackageId);
            }
            return true;
        } else if (scannedValue == "Print All Carton Labels") {
            if (this.state.isAnyItemUnpacked) {
                GlobalService.notification("error", this.translate("Label_Carton_Fully_Allocated_Require"));
            } else if (this.state.cartonLabel == 0) {
                GlobalService.notification("error", this.translate("Label_Carton_Require_Tooltip"));
            } else {
                this.autoPrintForAllPackage("cartonLabelAll");
            }
            return true;
        } else if (scannedValue == "Print Transaction Labels") {
            if (this.state.isAnyItemUnpacked) {
                GlobalService.notification("error", this.translate("Label_Transaction_Fully_Allocated_Require"));
            } else if (this.state.transactionLabel == 0) {
                GlobalService.notification("error", this.translate("Label_Transaction_Require_Tooltip"));
            } else {
                this.autoPrintForAllPackage("transactionLabelAll");
            }
            return true;
        } else if (scannedValue == "New Package") {
            var packAndShipCombobox = $("#PackAndShiAddaPackage").data("kendoComboBox");
            if (packAndShipCombobox) {
                packAndShipCombobox.focus();
            }
            return true;
        } else if (scannedValue == "Custom Dimensions") {
            this.setState({
                isOpenCustomPackageModel: true,
                customPackageObj: {},
            });
            return true;
        } else if (scannedValue == "Clear All") {
            this.deleteAllConfirm();
            return true;
        } else if (scannedValue == "Select All Unpacked Items") {
            this.onSelectUnpackHandler(true);
            return true;
        } else if (scannedValue == "Pack Selected") {
            if (this.state.isPackSelectedBtnEnabled) {
                this.onClickPackSelectedItem();
                return true;
            } else {
                var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
                var selectionCounter = gridRef.select() && gridRef.select().length;
                if (selectionCounter == 0) {
                    GlobalService.notification("error", "Please select an item(s) to add into package.");
                    return true;
                }
            }
        } else if (scannedValue == "Get Rates") {
            if(this.props.sharedSelectedOrderIds.length == 1 && !this.state.isAnyItemUnpacked) {
                this.onClickGetRates();
                return true;
            } else {
                return false;
            }
        } else if (scannedValue == "NxtLabTemp") {
            if (this.state.packAdnShipObj.processAs == "Standard" && this.state.packAdnShipObj.comboLabelTemplate && this.state.menuOptions[0].Reports.length) {
                let currentIndex = this.state.menuOptions[0].Reports.map(function(element) { return element.Name; }).indexOf(this.state.packAdnShipObj.comboLabelTemplate);
                let nextIndex = (currentIndex + 1) % this.state.menuOptions[0].Reports.length;
                this.setState({
                    packAdnShipObj: Object.assign(this.state.packAdnShipObj, {
                        comboLabelTemplate: this.state.menuOptions[0].Reports[nextIndex].Name
                    }),
                });
                return true;
            } else {
                return false;
            }
        } else if (scannedValue == "CrtLabTemp") {
            if (this.state.cartonLabelOptions.length) {
                let currentIndex = this.state.cartonLabelOptions.map(function(element) { return element.LabelTemplateId; }).indexOf(this.state.cartonLabel);
                let nextIndex = (currentIndex + 1) % this.state.cartonLabelOptions.length;
                let nextLabelTemplateId = this.state.cartonLabelOptions[nextIndex].LabelTemplateId;
                this.setState({
                    cartonLabel: nextLabelTemplateId
                });
                return true;
            } else {
                return false;
            }
        } else if (scannedValue == "TranLabTemp") {
            if (this.state.transactionLabelOptions.length) {
                let currentIndex = this.state.transactionLabelOptions.map(function(element) { return element.LabelTemplateId; }).indexOf(this.state.transactionLabel);
                let nextIndex = (currentIndex + 1) % this.state.transactionLabelOptions.length;
                let nextLabelTemplateId = this.state.transactionLabelOptions[nextIndex].LabelTemplateId;
                this.setState({
                    transactionLabel: nextLabelTemplateId
                });
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    getPackageByPackageDefIdFromPackageOptions(id) {
        return this.state.packagesOptions.find((element) => {
            if (element.PackageDefId == id) {
                return element;
            }
        });
    }
    onShipBtnClick = (fromCalled) => {
        if (!this.checkIfOrderAlreadyInPrinting("ship")) {
            this.onCheckToCallAutoRateShopperBeforeShip(fromCalled);
        }
    };
    onCheckToCallAutoRateShopperBeforeShip = (fromCalled) => {
        if (
            this.order.SmallParcelAutoRateShopOnShip &&
            this.props.sharedSelectedOrderIds.length == 1 &&
            // this.state.carrier == this.translate('Label_None_Selected') &&
            // this.state.service ==  this.translate('Label_None_Selected') &&
            this.isCallAutoRateAPI
        ) {
            this.setState({ gifShipBtn: true });
            // Call Auto Rate Starter API
            let dataObj = {
                "OrderIds": this.props.sharedSelectedOrderIds,
                "ReturnAllRates": false,
                "MultiOrderRateShopOnShip": false
            }
            smallParcelService.parcelAutoRateShopperStarter(dataObj).then((response) => {
                if(response && response.data) {
                    this.getParcelAutoRateShopperStatus(response.data, fromCalled);
                }
            });
        } else if (this.props.sharedSelectedOrderIds.length > 1 && this.isCallAutoRateAPIMultiOrder) {
            this.setState({ gifShipBtn: true });
            // Call Auto Rate Starter API
            let dataObj = {
                "OrderIds": this.props.sharedSelectedOrderIds,
                "ReturnAllRates": false,
                "MultiOrderRateShopOnShip": true
            }
            smallParcelService.parcelAutoRateShopperStarter(dataObj).then((response) => {
                if(response && response.data) {
                    this.getParcelAutoRateShopperStatus(response.data, fromCalled);
                }
            });
        } else {
            this.onShipClick(fromCalled);
        }
    };
    onShipClick = async (fromCalled) => {
        if (fromCalled != "fromPack") {
            this.setState({ gifShipBtn: true });
            await this.updateScaleWeight(this.currentPackage);
        }
        if (fromCalled == "fromPackNShip") {
            this.setState({
                gifPackNShipButton: true,
                packCompleteLoading: true,
                gifShipBtn: true,
            });
        }

        let labelFormat;

        if (this.state.packAdnShipObj.labelFormat == "Pdf") {
            if (this.state.packAdnShipObj.processAs == "High Volume" || (this.state.packAdnShipObj.processAs == "Standard" && this.state.packAdnShipObj.comboLabelTemplate == "Default")) {
                labelFormat = "Pdf";
            } else if (this.state.packAdnShipObj.processAs == "High Volume" || (this.state.packAdnShipObj.processAs == "Standard" && this.state.packAdnShipObj.comboLabelTemplate != "Default")) {
                labelFormat = "Png";
            }
        } else if (this.state.packAdnShipObj.labelFormat == "Png") {
            labelFormat = "Png";
        } else {
            labelFormat = "Zpl";
        }

        if (this.state.packAdnShipObj.labelFormat == "Zpl" && this.state.packAdnShipObj.processAs == "Standard") {
            smallParcelService
                .getZplFormattedLabels(this.props.sharedSelectedOrderIds, this.state.packAdnShipObj.returnLabel, this.state.rights["Integrations.SmallParcelPartner"])
                .then((response) => {

                    $.each(this.props.sharedSelectedOrderIds, function (i, orderId) {
                        window.smallParcelPackNShipTransactionIds.push({
                            orderId: orderId,
                            StatusRelativeUri: response.data.StatusRelativeUri,
                        });
                    });
                    var targetElement = $('[data-wms-selector="packAndShipTransactionFinish"]');
                    this.$rootScope.animationMoveIcon(targetElement);
                    this.$rootScope.taskStarted();
                    var transferOrderObj = {
                        type: "zplFormatedLabel",
                        relativeUri: response.data.StatusRelativeUri,
                        isMultiId: this.props.sharedSelectedOrderIds.length > 1,
                        isIntegratedPackingSlips: this.state.isIntegratedPackingSlips,
                        packingSlipsData: {
                            "TemplatedRunner": this.state.packAdnShipObj.SmallParcelPackSlip,
                            "TransactionIds": this.props.sharedSelectedOrderIds,
                            "CustomerId": this.state.packAndShipOrderInfo.customerId,
                            "TemplateId": this.state.packAdnShipObj.SmallParcelAddressTemplate,
                            "OutputType": 'application/pdf'
                        },
                    };
                    var event = new CustomEvent("showProgressModal", {
                        detail: transferOrderObj,
                    });
                    document.dispatchEvent(event);
                    GlobalService.notification("success", this.translate("Label_ZPL_Successfully_Queued"));
                    this.props.onClosePackAndShipModel();
                })
                .finally(() => {});
        } else if (this.state.packAdnShipObj.labelFormat == "Pdf" && this.state.packAdnShipObj.processAs == "Standard") {
            let customerId = this.props.sharedSelectedOrderIds.length == 1 ? this.state.packAndShipOrderInfo.customerId : null;
            let seletedReport = this.state.menuOptions[0].Reports.find((elem) => elem.Name == this.state.packAdnShipObj.comboLabelTemplate);
            smallParcelService
                .getPdfFormattedLabels(this.props.sharedSelectedOrderIds, customerId, labelFormat, seletedReport.TemplatedRunnerKey, this.state.packAdnShipObj.returnLabel, this.state.rights["Integrations.SmallParcelPartner"])
                .then((response) => {
                    $.each(this.props.sharedSelectedOrderIds, function (i, orderId) {
                        window.smallParcelPackNShipTransactionIds.push({
                            orderId: orderId,
                            StatusRelativeUri: response.data.StatusRelativeUri,
                        });
                    });
                    var targetElement = $('[data-wms-selector="packAndShipTransactionFinish"]');
                    this.$rootScope.animationMoveIcon(targetElement);
                    this.$rootScope.taskStarted();
                    var transferOrderObj = {
                        type: "pdfFormatedLabel",
                        relativeUri: response.data.StatusRelativeUri,
                        pdfFileName: "ShippingLabel-" + this.props.sharedSelectedOrderIds.join("-"),
                        isMultiId: this.props.sharedSelectedOrderIds.length > 1,
                        isIntegratedPackingSlips: this.state.isIntegratedPackingSlips,
                        packingSlipsData: {
                            "TemplatedRunner": this.state.packAdnShipObj.SmallParcelPackSlip,
                            "TransactionIds": this.props.sharedSelectedOrderIds,
                            "CustomerId": this.state.packAndShipOrderInfo.customerId,
                            "TemplateId": this.state.packAdnShipObj.SmallParcelAddressTemplate,
                            "OutputType": 'application/pdf'
                        },
                    };
                    var event = new CustomEvent("showProgressModal", {
                        detail: transferOrderObj,
                    });
                    document.dispatchEvent(event);
                    GlobalService.notification("success", this.translate("Label_PDf_Successfully_Queued"));
                    this.props.onClosePackAndShipModel();
                })
                .finally(() => {});
        } else if (this.state.packAdnShipObj.processAs == "High Volume") {
            smallParcelService
                .createShippingLabelsHighVolumeBatch(this.props.sharedSelectedOrderIds, this.state.packAdnShipObj.batchname, labelFormat)
                .then((response) => {
                    if ((typeof response != "undefined" && response.status == 204) || response.status == 200) {
                        // this.resetGridSelectionAndButton();
                        GlobalService.notification("success", this.translate("Label_Pack_And_Ship_Success"));

                        this.props.onResetGridSelection();
                        setTimeout(() => {
                            this.props.onClosePackAndShipModel();
                        }, 100);
                    }
                })
                .finally(() => {
                    this.setState({
                        gifShipBtn: false,
                        gifPackNShipButton: false,
                    });
                });
        }
    };
    onGridSelection = (e) => {
        var tmpIsPackSelectedBtnEnabled = false;
        var tmpPackSelectedBtnTooltip = "";
        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
        var selectionCounter = gridRef.select() && gridRef.select().length;
        if ((e && e.shiftKey) || (e && (e.ctrlKey || e.metaKey)) || selectionCounter > 1) {
            tmpIsPackSelectedBtnEnabled = true;
            var selectionData = gridRef.select();
            var unAssignSkuUid = [];
            $.grep(selectionData, (rowElement) => {
                const selectedItem = gridRef.dataItem(rowElement);
                if (selectedItem.PackageId) {
                    tmpPackSelectedBtnTooltip = this.translate("Label_Select_Item_Already_Packed");
                }
            });
            gridRef.select(unAssignSkuUid.join(", "));
        }
        if (selectionCounter == 0) {
            tmpIsPackSelectedBtnEnabled = false;
        }
        this.selectedItems = [];
        var rows = gridRef.select();
        $.map(rows, (row) => {
            this.selectedItems.push(_.cloneDeep(gridRef.dataItem(row)));
        });

        this.setState(
            {
                isPackSelectedBtnEnabled: tmpIsPackSelectedBtnEnabled,
                packSelectedBtnTooltip: tmpPackSelectedBtnTooltip,
            },
            this.listGrid_rowClick
        );
    };

    closeAddNonSkuQuantityModal = () => {
        this.setState({ isOpenAddNonSkuQuantityModal: false });
    };

    onNonSkuQtyDecrement = () => {
        if(this.state.nonSkuItemQuantity != 0) {
            let tempNonSkuItemQuantity = parseInt(this.state.nonSkuItemQuantity) - 1;
            this.setState({ nonSkuItemQuantity: tempNonSkuItemQuantity });
        }
    };

    onNonSkuQtyIncrement = () => {
        let tempNonSkuItemQuantity = parseInt(this.state.nonSkuItemQuantity) + 1;
        this.setState({ nonSkuItemQuantity: tempNonSkuItemQuantity });
    };

    onNonSkuItemQtyChangeFunc = (e) => {
        if (!e.target.value) {
            e.target.value = 0;
        }
        var tempNonSkuItemQuantity = parseInt(e.target.value);
        this.setState({ nonSkuItemQuantity: tempNonSkuItemQuantity });
    };

    nonSkuQtyNext = () => {
        this.closeAddNonSkuQuantityModal();
        this.setFocusOnScanNonSkuAndSelectValueQtyPopup();
        this.addNonSkuItems(this.nonSkuContent.InsertId, this.state.nonSkuItemQuantity);
    };

    addNonSkuItems = (insertId, insertedQty) => {
        let orderId = this.props.sharedSelectedOrderIds[0];
        kendo.ui.progress($("#PackAndShipTransactionModel"), true);
        smallParcelService
            .packNonSkuInserts(orderId, insertId, insertedQty)
            .then((response) => {
                // Get Updated Order Info
                smallParcelService.getOrderPackInfo(orderId).then((response) => {
                    this.order = response;
                    this.listGrid_dataBind();
                    this.setState({ scanGridKey: "" });
                    kendo.ui.progress($("#PackAndShipTransactionModel"), false);
                });
        });
    };

    listGrid_rowClick = () => {
        var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
        var selectedItem = gridRef.dataItem(gridRef.select());
        // Checked If Inserts Row Selected Then Clear Selection
        if(selectedItem && selectedItem.Sku == 'INSERTS') {
            if(this.state.isOneQtyPack) {
                this.addNonSkuItems(selectedItem.InsertId, selectedItem.InsertedQty+1);
            } else {
                this.nonSkuContent = selectedItem;
                this.setState({
                        isOpenAddNonSkuQuantityModal: true,
                        nonSkuItemQuantity: selectedItem.InsertedQty ? selectedItem.InsertedQty : 0
                    },
                    this.setFocusOnScanNonSkuAndSelectValueQtyPopup
                );
            }
            return;
        }
        if (this.state.isPackSelectedBtnEnabled && selectedItem) {
            this.content = selectedItem;
            if (this.content.PackageId == 0 && !this.currentPackage.PackageUri && !this.currentPackage.PackageDefId && this.currentPackage.Name !== this.translate("Label_Custom_Dimensions")) {
                GlobalService.notification("error", "No package selected please select one");
                this.setState({
                    isPackSelectedBtnEnabled: false,
                    selectUnpack: false,
                });
                gridRef.clearSelection();
            }
            return;
        } else if (selectedItem) {
            this.content = selectedItem;
            // get primary Uom and check for item isIntegral or not
            this.isIntegral = GlobalService.getIsIntegeralFlagByType(this.content.PrimaryUOM);
            // Update quantity regex expression
            var quantityRegx = this.isIntegral ? /(^[0-9]+$|^$)/ : /^[0-9]*(?:\.[0-9]{0,3})?$/;
            this.setState({
                quantityRegx: quantityRegx,
            });
            if (this.content.PackageId > 0) {
                this.setState({
                    isOpenEditItemQtyModel: true,
                    packedInSaveDisable: true,
                    selectPackedIn: this.content.PackageId,
                });
                this.itemPopupOpen();
            } else if (this.currentPackage.PackageUri || this.currentPackage.PackageDefId || this.currentPackage.Name == this.translate("Label_Custom_Dimensions")) {
                this.lastPackedSelectedIndex = 0;
                var scanGridKeyValue = this.state.scanGridKey.trim();
                if ((this.order.SmallParcelDisableClickToPack && !this.isEnteredValue) || (this.order.SmallParcelDisableClickToPack && !scanGridKeyValue && scanGridKeyValue.toLowerCase() != this.content.Sku.toLowerCase())) {
                    return;
                }
                this.isEnteredValue = false;
                this._packContent();
            } else {
                // no package selected please check
                GlobalService.notification("error", "No package selected please select one");
            }
        }
    };
    itemPopupOpen = () => {
        var labelCount = this.getLabelCounts(this.order.PackageData, this.currentPackage);
        var self = this;
        //build package defs
        var pkgs = this.order.PackageData.reduce(function (obj, p) {
            var isCur = p.PackageId == self.content.PackageId;
            obj.push({
                text: labelCount[p.Name].total > 1 ? p.Name + " (" + ++labelCount[p.Name].i + ")" : p.Name,
                value: p.PackageId,
                disabled: isCur,
                selected: isCur,
            });
            // obj.push($("<option/>")
            //     .text(labelCount[p.Name].total > 1 ?
            //         p.Name + ' (' + ++labelCount[p.Name].i + ')' : p.Name)
            //     .attr("value", p.PackageId)
            //     .attr("disabled", isCur)
            //     .attr("selected", isCur));
            return obj;
        }, []);
        this.setState({
            editPackedInOptions: pkgs,
        });
    };
    _packContent = () => {
        this.showT = false;
        this.showQ = false;
        this.showS = false;

        // if (this.content.Qualifier || this.content.Lot || this.content.Serial || this.content.ExpirationDate) { this.showT = true; }
        var packagedQty = this.qtyBaseOnUPC;
        if (this.isIntegral) {
            packagedQty = Math.ceil(this.qtyBaseOnUPC);
        }
        if (this.content.Remainder > 1 || !this.isIntegral) {
            if (this.state.isOneQtyPack) {
                this.content.PackedQty = this.qtyBaseOnUPC < this.content.Remainder ? packagedQty : this.content.Remainder;
            } else {
                this.showQ = true;
            }
        } else {
            this.content.PackedQty = 1;
        }
        if (this.content.OutboundMobileSerialization > 0) {
            this.showS = true;
        }
        // if (this.content.TrackSerialNumber > 0) { this.showS = true; }

        // if (this.showT) { this._trackby(); }
        if (this.showQ) {
            this._qty();
        } else if (this.showS) {
            this.checkNextItemToPackSelected("_packContent");
        } else {
            this._add("PackAndShipTransactionModel");
        }
    };
    _trackby = () => {
        // this._qty();
        var info = [];

        if (this.content.Qualifier) {
            info.push({ text: "Qualifier", value: this.content.Qualifier });
        }
        if (this.content.Lot) {
            info.push({ text: "Lot", value: this.content.Lot });
        }
        if (this.content.Serial) {
            info.push({ text: "Serial", value: this.content.Serial });
        }
        if (this.content.ExpirationDate) {
            info.push({
                text: "Expiration Date",
                value: this.content.ExpirationDate,
            });
        }

        this.setState({
            isOpenTrackByInfoMOdel: true,
            trackByModelInfo: info,
        });
    };
    onCloseVerifyTrackByModel = () => {
        this.setState(
            {
                isOpenTrackByInfoMOdel: false,
                trackByModelInfo: [],
            },
            () => {
                this.setFocusOnScanAndSelectValue();
            }
        );
    };
    onConfirmVerifyTrackByModel = () => {
        this.setState({
            isOpenTrackByInfoMOdel: false,
            trackByModelInfo: [],
        });

        if (this.showQ) {
            this._qty();
        } else if (this.showS) {
            this._serial();
        } else {
            this._add("PackAndShipTransactionModel");
        }
    };
    _qty = () => {
        // : pending functionality input maximum qty same as content.Remainder and min quantity 1
        var qty = this.qtyBaseOnUPC;
        if (this.isIntegral) {
            qty = Math.ceil(qty);
        }
        if (this.qtyBaseOnUPC > this.content.Remainder) {
            qty = this.content.Remainder;
        }
        this.setState(
            {
                isOpenAddQuantity: true,
                scanPackedQty: "",
                invalidValue: false,
                remainingQuantity: this.content.Remainder - qty,
                itemQuantity: qty,
            },
            this.setFocusOnScanAndSelectValueQtyPopup
        );
    };
    setFocusOnSerialNumber = () => {
        if (this.refs.serialNumber) {
            // this.refs.serialNumber.refs.myinput.select();
            this.refs.serialNumber.refs.myinput.focus();
        }
    };
    _serial = () => {
        this.setState({
            isOpenSerialPopup: true,
            packSerialQty: this.content.PackedQty,
            serials: [],
            enteredSerialNumber: "",
        });
        this.setFocusOnSerialNumber();
        this.validateTrackBySerialAndOutBound([]);
    };
    onEnterSerialInput = () => {
        var list = this.state.serials;

        var val = this.state.enteredSerialNumber;
        var tmpPackSerialQty = this.state.packSerialQty;

        if (this.content.TrackSerialNumber == 4) {
            if (val == this.content.Serial || !this.content.Serial) {
                list.unshift(val);
            } else {
                GlobalService.notification("error", "Serial number invalid, expected: " + this.content.Serial);
            }
        } else {
            var isSerialNumberExistsInPacked = false;
            if (this.content.isSerialUnique && this.state.serials.indexOf(val) == -1) {
                for (let i = 0; i < this.order.PackageData.length; i++) {
                    const packageContentsArr = this.order.PackageData[i].PackageContents;
                    if (packageContentsArr != null && packageContentsArr.length >= 1) {
                        for (let c = 0; c < packageContentsArr.length; c++) {
                            const contentItem = packageContentsArr[c];
                            if (this.content.oiid == contentItem.OrderItemId && contentItem.SerialNumbers.indexOf(val) != -1) {
                                isSerialNumberExistsInPacked = true;
                                break;
                            }
                        }
                    }
                    if (isSerialNumberExistsInPacked) {
                        break;
                    }
                }
            }
            if (this.content.isSerialUnique && (this.state.serials.length == 0 || this.state.serials.indexOf(val) == -1) && isSerialNumberExistsInPacked == false) {
                list.unshift(val);
            } else if (this.content.isSerialUnique) {
                GlobalService.notification("error", "Please enter unique serial number");
            }

            if (this.content.isSerialUnique == false) {
                list.unshift(val);
            }
        }

        tmpPackSerialQty = this.content.PackedQty - list.length;
        this.setState({
            serials: list,
            packSerialQty: tmpPackSerialQty,
            enteredSerialNumber: "",
        });
        this.setFocusOnSerialNumber();

        this.validateTrackBySerialAndOutBound(list);
    };

    validateTrackBySerialAndOutBound = (list) => {
        let isEnabledNext = false;
        // 0 Do not use Outbound Serialization
        // 1 Ignore Quantity Mismatches
        // 2 Require Quantities to Match
        if (this.content.OutboundMobileSerialization == 0) {
            isEnabledNext = true;
        } else if (this.content.OutboundMobileSerialization == 1) {
            if (list.length > 0) {
                isEnabledNext = true;
            }
        } else if (this.content.OutboundMobileSerialization == 2) {
            if (list.length >= this.content.PackedQty) {
                isEnabledNext = true;
            }
        }

        // console.log("validateTrackBySerialAndOutBound before isEnableNextOnSerialPopup", this.state.isEnabledNextOnSerialPopup);
        console.log("validateTrackBySerialAndOutBound " + isEnabledNext + " this.content.OutboundMobileSerialization: " + this.content.OutboundMobileSerialization);
        this.setState({
            isEnabledNextOnSerialPopup: isEnabledNext,
        });
        // console.log("validateTrackBySerialAndOutBound after isEnableNextOnSerialPopup", this.state.isEnabledNextOnSerialPopup);
        // If all serial added click on next
        if (list.length >= this.content.PackedQty) {
            this.onNextSerialPopup();
        }
    };

    onNextSerialPopup = () => {
        this.content.SerialNumbers = this.state.serials;
        // console.log("onNextSerialPopup this.content.SerialNumbers : ", this.content.SerialNumbers);
        this.checkNextItemToPackSelected();
    };

    closeSerialPopup = () => {
        this.setState({
            isOpenSerialPopup: false,
        });
    };

    listGrid_groupClick = (e) => {
        var g = $(e).find(".group-icon-wrapper").attr("package-id");
        if ($.isNumeric(g) && g != this.currentPackage.PackageId && parseInt(g) > 0) {
            this._setCurrentPackage(parseInt(g));
            this.listGrid_dataBind();
        } else if (g == 0) {
            let selectedUniquePackageName = $(e).find(".group-icon-wrapper").attr("unique-package-name");
            let selectedPkgIndex = _.findIndex(
                this.previousSelectedPackageArr,
                (packageItem) => {
                    return packageItem.uniquePackageName == selectedUniquePackageName;
                },
                0
            );
            if (selectedPkgIndex != -1) {
                this.previousSelectedPackage = this.currentPackage;
                this.currentPackage = this.previousSelectedPackageArr[selectedPkgIndex];
                this.activePackageIconChange();
                this.listGrid_dataBind();
            }
        }
    };
    activePackageIconChange = () => {
        $(".selected-package").remove(); // remove old active package
        var activePackageRow = $("#packAndShipTransactionGrid")
            .find("span[package-id='" + this.currentPackage.PackageId + "']")
            .closest(".k-reset");
        if (this.currentPackage.PackageId == 0) {
            activePackageRow = $("#packAndShipTransactionGrid")
                .find("span[unique-package-name='" + this.currentPackage.uniquePackageName + "']")
                .last()
                .closest(".k-reset");
        }
        activePackageRow.prepend($('<i class="fas fa-caret-right selected-package"></i>'));

        if (this.state.scaleChangeWeight && this.previousSelectedPackage) {
            this.updateScaleWeight(this.previousSelectedPackage);
            this.previousSelectedPackage = null;
        }
    };
    updateScaleWeight = (currentPackage) => {
        if (this.state.scaleChangeWeight && this.state.scaleChangeWeight != "0.0" && this.state.scaleChangeWeight != "0.0000" && currentPackage.Weight != this.state.scaleChangeWeight && this.state.isDeviceConnect && currentPackage.PackageId) {
            var weight = this.state.scaleChangeWeight;
            var pkg = this._getPackageFromOrder(currentPackage.PackageId);
            if (!pkg) {
                return;
            }
            pkg.PackageDefId = currentPackage.PackageDefId;
            var self = this;
            var data = {
                packageData: pkg,
            };
            kendo.ui.progress($("#packAndShipTransactionGrid"), true);
            data.calculateWeight = false;
            data.packageData.Weight = weight;
            // this.state.scaleChangeWeight = 0;
            this.setState({
                isPackNShipLoading: true,
                packNShipLoadingMsg: "Saving weight for current Package...",
            });
            return smallParcelService
                .updatePackage(data)
                .then((response) => {
                    var result = response.data;
                    $.each(self.order.PackageData, function (i, p) {
                        if (p.PackageId == pkg.PackageId) {
                            self.order.PackageData[i] = result;
                        }
                    });
                    GlobalService.notification("success", this.translate("Label_Wieght_Update_Success"));
                    this.isDisabledPack = false;
                    // Which order we have open with have one or more updated packed item.
                    this.isAnyItemPacked = true;
                    this.listGrid_dataBind();
                })
                .finally(() => {
                    this.setState({
                        isPackNShipLoading: false,
                        packNShipLoadingMsg: "",
                    });
                    kendo.ui.progress($("#packAndShipTransactionGrid"), false);
                    return true;
                });
        } else {
            return true;
        }
    };

    _setCurrentPackage = (packageId) => {
        if (!$.isNumeric(packageId)) {
            throw "packageId is not numeric; figure it out";
        }
        this.previousSelectedPackage = this.currentPackage;
        this.currentPackage = this.order.PackageData.find(function (p) {
            return p.PackageId == packageId;
        });

        this.activePackageIconChange();
    };

    _getPackageFromOrder = (packageId) => {
        return this.order.PackageData.find(function (p) {
            return p.PackageId == packageId;
        });
    };

    closeQuantityModal = () => {
        this.qtyBaseOnUPC = 1;
        this.setState(
            {
                isOpenAddQuantity: false,
            },
            () => {
                if (this.showS == false || this.state.isOpenSerialPopup == false) {
                    this.setFocusOnScanAndSelectValue();
                }
            }
        );
    };

    setFocusOnScanAndSelectValue = () => {
        if (this.refs.scanGridKey) {
            this.setState({
                scanGridKey: "",
            });
            this.refs.scanGridKey.refs.myinput.focus();
            this.refs.scanGridKey.refs.myinput.select();
        }
    };

    setFocusOnAddPackageAndSelectValue = () => {
        var packAndShipCombobox = $("#PackAndShiAddaPackage").data("kendoComboBox");
        if (packAndShipCombobox) {
            packAndShipCombobox.focus();
        }
    };

    onQtyIncrement = (e) => {
        var itemQuantity = 0;
        var qty = e ? 1 : this.qtyBaseOnUPC;
        if (this.isIntegral) {
            qty = Math.ceil(qty);
        }
        if (this.isIntegral) {
            itemQuantity = parseInt(this.state.itemQuantity) + Math.ceil(qty);
        } else {
            itemQuantity = (parseFloat(this.state.itemQuantity) + qty).toFixed(3);
        }
        var remainingQuantity = this.state.remainingQuantity - qty;
        if (itemQuantity >= this.content.Remainder) {
            itemQuantity = this.content.Remainder;
            remainingQuantity = 0;
        }
        this.setState({
            itemQuantity: itemQuantity,
            remainingQuantity: remainingQuantity,
        });
        if (remainingQuantity === 0) {
            this.qtyNext(e, this.content.Remainder);
        }
        // no need to check for valid quantity because pack item if quantity is same as remainder
        this.checkValidInput(itemQuantity);
    };

    checkValidInput = (inputValue) => {
        var invalidValue = true;
        if (inputValue && inputValue <= this.content.Remainder) {
            invalidValue = false;
        }
        this.setState({
            invalidValue: invalidValue,
        });
    };
    onQtyDecrement = (e) => {
        var remainingQuantity = 0;
        var itemQuantity = 0;
        var qty = e ? 1 : this.qtyBaseOnUPC;
        if (this.isIntegral) {
            qty = Math.ceil(qty);
        }
        if (this.isIntegral) {
            itemQuantity = parseInt(this.state.itemQuantity) - Math.ceil(qty);
        } else {
            itemQuantity = (parseFloat(this.state.itemQuantity) - qty).toFixed(3);
        }

        if (itemQuantity < 0) {
            // avoid - qty
            itemQuantity = 0;
        }
        if (itemQuantity < this.content.Remainder) {
            remainingQuantity = this.content.Remainder - itemQuantity;
        }

        this.setState({
            itemQuantity: itemQuantity,
            remainingQuantity: remainingQuantity,
        });

        this.checkValidInput(itemQuantity);
    };
    qtyNext = (e, itemQuantity) => {
        var val = this.state.itemQuantity;
        if (itemQuantity) {
            val = itemQuantity;
        }
        if (val && val > 0) {
            if (val <= this.content.Remainder) {
                this.content.PackedQty = val;
            }
            if (this.showS) {
                this.closeQuantityModal();
                this.lastPackedSelectedIndex = 0;
                this.checkNextItemToPackSelected("qtyNext");
            } else {
                this._add(this.props.windowId + "AddItemQuantity");
            }
        }
    };
    handleScanGridKeyChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    _add = (loadingContentId) => {
        if (this.currentPackage.PackageUri) {
            // id packageUri already than packageContent
            var isUpdatePackageContent = false;
            var tmpUpdatePackageContentsData = [];
            var selectionArr = this.selectedItems;
            var packageData = this.order.PackageData.find((p) => {
                return p.PackageId == this.currentPackage.PackageId;
            });

            if (packageData && packageData.PackageContents) {
                if (packageData.PackageContents.length > 0) {
                    for (let contentIndex = 0; contentIndex < packageData.PackageContents.length; contentIndex++) {
                        if (this.state.isPackSelectedBtnEnabled) {
                            for (let i = 0; i < selectionArr.length; i++) {
                                const selectedItem = selectionArr[i];
                                if (selectedItem.oiid) {
                                    if (packageData.PackageContents[contentIndex].OrderItemId == selectedItem.oiid && packageData.PackageContents[contentIndex].ReceiveItemId == selectedItem.riid) {
                                        var packageContentData = packageData.PackageContents[contentIndex];
                                        var newPackedQty = this.getRemainingQty(selectedItem);
                                        var oldPackedQty = packageContentData.PackedQty;
                                        packageContentData.PackedQty = newPackedQty + oldPackedQty;
                                        // merge serial numbers array if already exist
                                        if (selectedItem.SerialNumbers && selectedItem.SerialNumbers.length && packageContentData.SerialNumbers && packageContentData.SerialNumbers.length) {
                                            packageContentData.SerialNumbers = selectedItem.SerialNumbers.concat(packageContentData.SerialNumbers);
                                        }

                                        if (this.isIntegral) {
                                            packageContentData.PackedQty = parseInt(newPackedQty) + parseInt(oldPackedQty);
                                        } else {
                                            packageContentData.PackedQty = parseFloat(newPackedQty) + parseFloat(oldPackedQty);
                                        }
                                        tmpUpdatePackageContentsData.push(packageContentData);
                                        isUpdatePackageContent = true;
                                    }
                                }
                            }
                        } else {
                            if (packageData.PackageContents[contentIndex].OrderItemId == this.content.oiid && packageData.PackageContents[contentIndex].ReceiveItemId == this.content.riid) {
                                var packageContentData = packageData.PackageContents[contentIndex];
                                var newPackedQty = this.content.PackedQty;
                                var oldPackedQty = packageContentData.PackedQty;
                                packageContentData.PackedQty = newPackedQty + oldPackedQty;
                                // merge serial numbers array if already exist
                                if (this.content.SerialNumbers && this.content.SerialNumbers.length && packageContentData.SerialNumbers && packageContentData.SerialNumbers.length) {
                                    packageContentData.SerialNumbers = this.content.SerialNumbers.concat(packageContentData.SerialNumbers);
                                }

                                if (this.isIntegral) {
                                    packageContentData.PackedQty = parseInt(newPackedQty) + parseInt(oldPackedQty);
                                } else {
                                    packageContentData.PackedQty = parseFloat(newPackedQty) + parseFloat(oldPackedQty);
                                }
                                tmpUpdatePackageContentsData.push(packageContentData);
                                isUpdatePackageContent = true;
                            }
                        }
                    }
                }
            }

            if (isUpdatePackageContent) {
                var finalObject = {
                    packageContentsData: tmpUpdatePackageContentsData,
                };
                kendo.ui.progress($("#" + loadingContentId), true);
                this.isSerialNumberDisableToAddNew = true;
                smallParcelService
                    .updateItemToPackageContent(finalObject)
                    .then((response) => {
                        var result = response.data[0];
                        var pkg = this.order.PackageData.find((p) => {
                            return p.PackageId == result.PackageId;
                        });
                        if (pkg.PackageContents.length > 0) {
                            for (let contentIndex = 0; contentIndex < pkg.PackageContents.length; contentIndex++) {
                                packageContentData = pkg.PackageContents[contentIndex];
                                if (packageContentData.OrderItemId == result.OrderItemId && packageContentData.ReceiveItemId == result.ReceiveItemId) {
                                    pkg.PackageContents[contentIndex].PackedQty = result.PackedQty;
                                }
                            }
                        }

                    // this.updatedPackageWeight(this.currentPackage.PackageUri);
                    // Enable pack button after package item change when order is already packed
                    this.isDisabledPack = false;
                    // Which order we have open with have one or more item into package content.
                    this.isAnyItemPacked = true;
                    this.qtyBaseOnUPC = 1;
                    if (selectionArr.length != tmpUpdatePackageContentsData.length) {
                        this.checkAndAddItemsToPackage(selectionArr, tmpUpdatePackageContentsData, loadingContentId)
                    } else {
                        this.updatedPackageWeight(this.currentPackage.PackageUri);
                        this.handlePackageAddSuccess();
                    }
                }).finally(() => {
                    if (selectionArr.length == tmpUpdatePackageContentsData.length) {
                        kendo.ui.progress($('#' + loadingContentId), false);
                        this.isSerialNumberDisableToAddNew = false
                    }
                });
            } else if (selectionArr.length != tmpUpdatePackageContentsData.length) {
                this.checkAndAddItemsToPackage(selectionArr, tmpUpdatePackageContentsData, loadingContentId);
            }
        } else if (this.currentPackage.PackageDefId > 0 || this.currentPackage.Name == this.translate("Label_Custom_Dimensions")) {
            // id packageUri already than package
            var PackageContents = [];
            if (this.state.isPackSelectedBtnEnabled) {
                var selectionArr = this.selectedItems;
                for (let i = 0; i < selectionArr.length; i++) {
                    const selectedItem = selectionArr[i];
                    var packedQty = this.getRemainingQty(selectedItem);
                    if (selectedItem.oiid) {
                        PackageContents.push({
                            OrderItemId: selectedItem.oiid,
                            PackedQty: packedQty, // we need to check and update it : pending
                            ReceiveItemId: selectedItem.riid,
                            SerialNumbers: selectedItem.SerialNumbers, // we need to check and update it   : pending
                            Lot: selectedItem.Lot, // this is null right now
                            Serial: selectedItem.Serial, // this is null right now
                            ExpirationDate: selectedItem.ExpirationDate, // this is null right now
                        });
                    }
                }
            } else {
                PackageContents = [
                    {
                        OrderItemId: this.content.oiid,
                        PackedQty: this.content.PackedQty, // we need to check and update it : pending
                        ReceiveItemId: this.content.riid,
                        SerialNumbers: this.content.SerialNumbers, // we need to check and update it   : pending
                        Lot: this.content.Lot, // this is null right now
                        Serial: this.content.Serial, // this is null right now
                        ExpirationDate: this.content.ExpirationDate, // this is null right now
                    },
                ];
            }
            var finalObject = {
                packageData: {
                    PackageId: this.currentPackage.PackageId,
                    PackageDefId: this.currentPackage.PackageDefId,
                    Length: this.state.currentMeasurementStandardIsImperial ? this.currentPackage.LengthImperial : this.currentPackage.LengthMetric,
                    Width: this.state.currentMeasurementStandardIsImperial ? this.currentPackage.WidthImperial : this.currentPackage.WidthMetric,
                    Height: this.state.currentMeasurementStandardIsImperial ? this.currentPackage.HeightImperial : this.currentPackage.HeightMetric,
                    Ucc128: "",
                    Name: this.currentPackage.Name,
                    PackageUri: this.currentPackage.PackageUri,
                    PackageContents: PackageContents,
                },
                orderUri: "/orders/" + this.props.sharedSelectedOrderIds[0] + "{?detail,itemdetail,recalcautocharges}",
            };
            kendo.ui.progress($("#" + loadingContentId), true);
            this.isSerialNumberDisableToAddNew = true;
            // if (this.state.scaleChangeWeight) {
            //     finalObject.calculateWeight = false;
            //     finalObject.packageData.Weight = this.state.scaleChangeWeight;
            // }
            smallParcelService.addItemToPackage(finalObject).then((response) => {
                var result = response.data;
                this.order.PackageData.push(result);
                this.removePackageFromArr(this.currentPackage.uniquePackageName);
                this.previousSelectedPackage = this.currentPackage;
                this.currentPackage = result;
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                this.qtyBaseOnUPC = 1;
                // Which order we have open with have one or more item add into package.
                this.isAnyItemPacked = true;
                if (this.state.isPackSelectedBtnEnabled) {
                    this.setState({ isPackSelectedBtnEnabled: false });
                }
                this.handlePackageAddSuccess(true);

            }).finally(() => {
                kendo.ui.progress($('#' + loadingContentId), false);
                this.isSerialNumberDisableToAddNew = false
            });
        } else {
            console.log("Something wrong");
        }
    };

    getRemainingQty = (selectedItem) => {
        var packagedQty = this.qtyBaseOnUPC;
        if (this.isIntegral) {
            packagedQty = Math.ceil(this.qtyBaseOnUPC);
        }
        if (selectedItem.Remainder > 1 || !this.isIntegral) {
            if (this.state.isOneQtyPack) {
                packagedQty = this.qtyBaseOnUPC < selectedItem.Remainder ? packagedQty : selectedItem.Remainder;
            } else {
                packagedQty = selectedItem.Remainder;
            }
        } else {
            packagedQty = 1;
        }
        return packagedQty;
    };

    checkAndAddItemsToPackage = (selectionArr, tmpUpdatePackageContentsData, loadingContentId) => {
        var tmpPackageContentsData = [];
        if (this.state.isPackSelectedBtnEnabled) {
            for (let i = 0; i < selectionArr.length; i++) {
                const selectedItem = selectionArr[i];
                if (selectedItem.oiid) {
                    var findInUpdatePkgData = _.find(tmpUpdatePackageContentsData, { ReceiveItemId: selectedItem.riid });
                    if (findInUpdatePkgData) {
                        continue;
                    }
                    var packedQty = this.getRemainingQty(selectedItem);
                    tmpPackageContentsData.push({
                        PackageContentId: selectedItem.PackageContentId, // this is new fields check it : pending
                        PackageId: this.currentPackage.PackageId,
                        OrderItemId: selectedItem.oiid,
                        ReceiveItemId: selectedItem.riid,
                        PackedQty: packedQty, //  content.PackedQty : pending we need to check it
                        Lot: selectedItem.Lot,
                        Serial: selectedItem.Serial,
                        ExpirationDate: selectedItem.ExpirationDate,
                        SerialNumbers: selectedItem.SerialNumbers,
                        PackageContentUri: selectedItem.PackageContentUri, // this is new fields check it : pending
                        PackageUri: this.currentPackage.PackageUri,
                    });
                }
            }
        } else {
            tmpPackageContentsData = [
                {
                    PackageContentId: this.content.PackageContentId, // this is new fields check it : pending
                    PackageId: this.currentPackage.PackageId,
                    OrderItemId: this.content.oiid,
                    ReceiveItemId: this.content.riid,
                    PackedQty: this.content.PackedQty, //  content.PackedQty : pending we need to check it
                    Lot: this.content.Lot,
                    Serial: this.content.Serial,
                    ExpirationDate: this.content.ExpirationDate,
                    SerialNumbers: this.content.SerialNumbers,
                    PackageContentUri: this.content.PackageContentUri, // this is new fields check it : pending
                    PackageUri: this.currentPackage.PackageUri,
                },
            ];
        }
        var finalObject = {
            packageContentsData: tmpPackageContentsData,
            packageUri: this.currentPackage.PackageUri,
        };
        kendo.ui.progress($("#" + loadingContentId), true);
        this.isSerialNumberDisableToAddNew = true;
        smallParcelService
            .addItemToPackageContent(finalObject)
            .then((response) => {
                var result = response.data[0];
                var pkg = this.order.PackageData.find(function (p) {
                    return p.PackageId == result.PackageId;
                });
                pkg.PackageContents.push(result);
                this.updatedPackageWeight(this.currentPackage.PackageUri);
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                // Which order we have open with have one or more item add into package content.
                this.isAnyItemPacked = true;
                this.qtyBaseOnUPC = 1;
                this.handlePackageAddSuccess();
            })
            .finally(() => {
                kendo.ui.progress($("#" + loadingContentId), false);
                this.isSerialNumberDisableToAddNew = false;
            });
    };

    onItemQtyChangeFunc = (e) => {
        if (!e.target.value) {
            e.target.value = 0;
        }
        var itemQuantity;
        var Remainder;
        if (this.isIntegral) {
            itemQuantity = parseInt(e.target.value);
            Remainder = parseInt(this.content.Remainder);
        } else {
            itemQuantity = parseFloat(e.target.value);
            Remainder = parseFloat(this.content.Remainder);
        }

        var remainingQuantity = Remainder - itemQuantity;
        if (remainingQuantity < 0) {
            remainingQuantity = 0;
        }
        this.setState({
            itemQuantity: e.target.value,
            remainingQuantity: remainingQuantity,
        });

        this.checkValidInput(itemQuantity);
    };

    handlePackageAddSuccess = (shouldCallListGridDataBind = false) => {
        this.setState({
            isOpenAddQuantity: false,
            selectUnpack: false,
            isPackSelectedBtnEnabled: false,
        });
        this.closeSerialPopup();
        if (shouldCallListGridDataBind) {
            this.listGrid_dataBind();
        }
    };

    onGridDataBound = (e) => {
        var grid = e.sender;
        var element = grid.element;
        element.find(".hide-package-icon-wrapper").closest(".k-grouping-row").hide();
        element.find(".hide-package-items").closest(".k-grouping-row").next().hide();
        this.activePackageIconChange();
    };
    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $("#packAndShipTransactionGrid").data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = GlobalService.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setPackAndShipGridOptionInMetaData(finalSubmission);
    };

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        // var grid = $('#packAndShipTransactionGrid').data("kendoGrid");
        // var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        // GlobalService.sortDataset(sortArray, event.sort);
        // var gridColumn = grid.getOptions().columns;
        // var finalSubmission = this.state.kendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        // metaDataService.setPackAndShipGridOptionInMetaData(finalSubmission);
    };
    unpackItem = () => {
        this._unpack();
    };
    _unpack = () => {
        // $('#pack_item_popup').popup('close');
        var self = this;
        var pkg = this.order.PackageData.find(function (p) {
            return p.PackageId == self.content.PackageId;
        });
        kendo.ui.progress($("#" + this.props.windowId + "EditPackedItem"), true);
        if (pkg && pkg.PackageContents && pkg.PackageContents.length == 1) {
            //deletepackage
            smallParcelService.deletePackage(pkg.PackageUri).then((response) => {
                this.isDisabledPack = false;
                // Which order we have open with have no item into package content.
                this.isAnyItemPacked = false;
                this._handlePackageDelete(pkg.PackageId);
                this.isSmallParcelGridRefresh = true;
            }).finally(() => {
                kendo.ui.progress($('#' + this.props.windowId + 'EditPackedItem'), false);
            });
        } else {//delete packagecontent
            smallParcelService.deletePackageContent(self.content.PackageContentUri).then((response) => {
                // Which order we have open with have no item into package content.
                this.isAnyItemPacked = false;
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                this._handleContentDelete(pkg.PackageId);
                this.updatedPackageWeight(pkg.PackageUri);
                this.isSmallParcelGridRefresh = true;
            })
            .finally(() => {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackedItem"), false);
            });
        }
    };
    _handlePackageDelete = (pid) => {
        this.order.PackageData = this.order.PackageData.filter(function (p) {
            return p.PackageId != pid;
        });

        if (this.order.PackageData.length > 0) {
            this._setCurrentPackage(this.order.PackageData[this.order.PackageData.length - 1].PackageId);
        } else {
            this._clearCurrentPackage();
        }
        this.content = {};
        this.listGrid_dataBind();
        this.setState({
            isOpenEditItemQtyModel: false,
            selectUnpack: false,
            isPackSelectedBtnEnabled: false,
        });
    };
    _handleContentDelete = () => {
        var self = this;
        $.each(self.order.PackageData, function (i, p) {
            if (p.PackageId == self.content.PackageId) {
                self.order.PackageData[i].PackageContents = self.order.PackageData[i].PackageContents.filter(function (c) {
                    return c.PackageContentId != self.content.PackageContentId;
                });
                return false;
            }
        });
        this.content = {};
        this.listGrid_dataBind();
        this.setState({
            isOpenEditItemQtyModel: false,
            selectUnpack: false,
            isPackSelectedBtnEnabled: false,
        });
    };
    itemPackSelectChange = (e) => {
        var packedInSaveDisable = false;
        if (e.target.value == this.content.PackageId) {
            packedInSaveDisable = true;
        }
        this.setState({
            [e.target.name]: e.target.value,
            packedInSaveDisable: packedInSaveDisable,
        });
    };
    saveItem = () => {
        var newId = parseInt(this.state.selectPackedIn);
        var self = this;
        if (newId) {
            this._setCurrentPackage(newId);

            var pkg = this.order.PackageData.find(function (a) {
                return a.PackageId == self.content.PackageId;
            });
            // if (pkg.PackageContents.length > 1) {
            //     kendo.ui.progress($('#' + this.props.windowId + 'EditPackedItem' ), true);
            //     // Update package content
            //     var finalObject = {
            //         "packageContentData": {
            //             "PackageContentId": content.PackageContentId, // this is new fields check it : pending
            //             "PackageId": newId,
            //             "OrderItemId": content.oiid,
            //             "ReceiveItemId": content.riid,
            //             "PackedQty": content.PackedQty, //  content.PackedQty : pending we need to check it
            //             "Lot": content.Lot,
            //             "Serial":  content.Serial,
            //             "ExpirationDate": content.ExpirationDate,
            //             "SerialNumbers": content.SerialNumbers,
            //             "PackageContentUri": content.PackageContentUri // this is new fields check it : pending
            //             // "PackageUri": this.currentPackage.PackageUri
            //         }
            //     };

            //     smallParcelService.updateItemToPackageContent(finalObject).then((response) => {
            //         var result = response.data;
            //         // After update we need to remove old package contents from old package and move new one to other package id

            //         // First delete package content from other packages
            //         $.each(this.order.PackageData, function (i, p) {
            //             if (p.PackageId == content.PackageId) {
            //                 this.order.PackageData[i].PackageContents = this.order.PackageData[i].PackageContents.filter(function (c) { return c.PackageContentId != content.PackageContentId });
            //             }
            //         });
            //         // add package content to new one package
            //         var newPackage = this.order.PackageData.find(function (p) {
            //             return p.PackageId == newId;
            //         });
            //         newPackage.PackageContents.push(result);
            //         this.listGrid_dataBind();
            //         this.localKendoGrid.clearSelections(() => {
            //             this.localKendoGrid.repaintSelections();
            //         });
            //         this.closePackItem();
            //     }).finally(() => {
            //         kendo.ui.progress($('#' + this.props.windowId + 'EditPackedItem' ), false);
            //     });
            // }
            if (pkg.PackageContents.length > 1) {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackedItem"), true);
                smallParcelService
                    .deletePackageContent(self.content.PackageContentUri)
                    .then((response) => {
                        $.each(self.order.PackageData, function (i, p) {
                            if (p.PackageId == pkg.PackageId) {
                                self.order.PackageData[i].PackageContents = self.order.PackageData[i].PackageContents.filter(function (c) {
                                    return c.PackageContentId != self.content.PackageContentId;
                                });
                            }
                        });
                        this.updatedPackageWeight(self.content.PackageUri);
                        this.isSmallParcelGridRefresh = true;
                        this.packcontent(newId);
                    })
                    .finally(() => {});
            } else {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackedItem"), true);
                smallParcelService
                    .deletePackage(pkg.PackageUri)
                    .then((response) => {
                        //remove local instance
                        self.order.PackageData = self.order.PackageData.filter(function (p) {
                            return p.PackageId != pkg.PackageId;
                        });
                        this.isSmallParcelGridRefresh = true;
                        this.packcontent(newId);
                    })
                    .finally(() => {});
            }
        }
    };
    packcontent = (pkgId) => {
        var self = this;

        var isUpdatePackageContent = false;
        var tmpUpdatePackageContentsData = [];
        var selectionArr = this.selectedItems;
        var packageData = this.order.PackageData.find((p) => {
            return p.PackageId == pkgId;
        });
        if (packageData && packageData.PackageContents) {
            if (packageData.PackageContents.length > 0) {
                for (let contentIndex = 0; contentIndex < packageData.PackageContents.length; contentIndex++) {
                    if (packageData.PackageContents[contentIndex].OrderItemId == this.content.oiid && packageData.PackageContents[contentIndex].ReceiveItemId == this.content.riid) {
                        var packageContentData = packageData.PackageContents[contentIndex];
                        var newPackedQty = this.content.PackedQty;
                        var oldPackedQty = packageContentData.PackedQty;
                        packageContentData.PackedQty = newPackedQty + oldPackedQty;
                        // merge serial numbers array if already exist
                        if (this.content.SerialNumbers && this.content.SerialNumbers.length && packageContentData.SerialNumbers && packageContentData.SerialNumbers.length) {
                            if (typeof this.content.SerialNumbers === "object") {
                                this.content.SerialNumbers = Object.values(this.content.SerialNumbers);
                            }
                            packageContentData.SerialNumbers = this.content.SerialNumbers.concat(packageContentData.SerialNumbers);
                        }

                        if (this.isIntegral) {
                            packageContentData.PackedQty = parseInt(newPackedQty) + parseInt(oldPackedQty);
                        } else {
                            packageContentData.PackedQty = parseFloat(newPackedQty) + parseFloat(oldPackedQty);
                        }
                        tmpUpdatePackageContentsData.push(packageContentData);
                        isUpdatePackageContent = true;
                    }
                }
            }
        }

        if (isUpdatePackageContent) {
            var finalObject = {
                packageContentsData: tmpUpdatePackageContentsData,
            };
            this.isSerialNumberDisableToAddNew = true;
            smallParcelService
                .updateItemToPackageContent(finalObject)
                .then((response) => {
                    var result = response.data[0];
                    var pkg = this.order.PackageData.find((p) => {
                        return p.PackageId == result.PackageId;
                    });
                    if (pkg.PackageContents.length > 0) {
                        for (let contentIndex = 0; contentIndex < pkg.PackageContents.length; contentIndex++) {
                            packageContentData = pkg.PackageContents[contentIndex];
                            if (packageContentData.OrderItemId == result.OrderItemId && packageContentData.ReceiveItemId == result.ReceiveItemId) {
                                pkg.PackageContents[contentIndex].PackedQty = result.PackedQty;
                            }
                        }
                    }
                    // this.updatedPackageWeight(this.currentPackage.PackageUri);
                    // Enable pack button after package item change when order is already packed
                    this.isDisabledPack = false;
                    // Which order we have open with have one or more item add into package content.
                    this.isAnyItemPacked = true;
                    this.updatedPackageWeight(this.currentPackage.PackageUri);
                    this.listGrid_dataBind();
                    this.closePackItem();
            }).finally(() => {
                if (selectionArr.length == tmpUpdatePackageContentsData.length) {
                    kendo.ui.progress($('#' + this.props.windowId + 'EditPackedItem'), false);
                    this.isSerialNumberDisableToAddNew = false
                }
            });
        } else {
            var finalObject = {
                packageContentsData: [
                    {
                        PackageContentId: this.content.PackageContentId, // this is new fields check it : pending
                        PackageId: pkgId,
                        OrderItemId: this.content.oiid,
                        ReceiveItemId: this.content.riid,
                        PackedQty: this.content.PackedQty, //  content.PackedQty : pending we need to check it
                        Lot: this.content.Lot,
                        Serial: this.content.Serial,
                        ExpirationDate: this.content.ExpirationDate,
                        SerialNumbers: this.content.SerialNumbers,
                        PackageContentUri: this.content.PackageContentUri, // this is new fields check it : pending
                        PackageUri: this.currentPackage.PackageUri,
                    },
                ],
                packageUri: this.currentPackage.PackageUri,
            };
            smallParcelService.addItemToPackageContent(finalObject).then((response) => {
                var result = response.data;
                var pkg = self.order.PackageData.find(function (p) {
                    return p.PackageId == result[0].PackageId;
                });
                pkg.PackageContents.push(result[0]);
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                // Which order we have open with have one or more item add into package content.
                this.isAnyItemPacked = true;
                this.listGrid_dataBind();

                this.updatedPackageWeight(this.currentPackage.PackageUri);

                this.closePackItem();
            })
            .finally(() => {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackedItem"), false);
            });
        }
    };
    updatePackage = () => {
        var weight = this.state.editPackageWeight;

        var pkg = this._getPackageFromOrder(this.packageIdForEdit);
        pkg.PackageDefId = this.state.editSelectedPackageDefId;
        delete pkg.Weight;
        var self = this;
        var data = {
            packageData: pkg,
        };

        kendo.ui.progress($("#" + this.props.windowId + "EditPackageDetails"), true);
        data.calculateWeight = !this.weightChanged;
        if (data.calculateWeight == false) {
            data.packageData.Weight = weight;
        }
        if (data.packageData.PackageDefId == 0) {
            data.packageData.Name = this.translate("Label_Custom_Dimensions");
        }
        data.packageData.Height = this.state.editPackageHeight;
        data.packageData.Width = this.state.editPackageWidth;
        data.packageData.Length = this.state.editPackageLength;
        data.packageData.Description = this.state.packageContentDescription;

        const referenceFields = {
            reference1: this.state.reference1,
            reference2: this.state.reference2,
            reference3: this.state.reference3,
        };

        smallParcelService.setReferenceFields(pkg.PackageUri, referenceFields).then(function (response) {
            console.log(response);
        });

        smallParcelService
            .updatePackage(data)
            .then((response) => {
                var result = response.data;
                $.each(self.order.PackageData, function (i, p) {
                    if (p.PackageId == pkg.PackageId) {
                        self.order.PackageData[i] = result;
                    }
                });
                this.closePackGroup();
                // Enable pack button after package item change when order is already packed
                this.isDisabledPack = false;
                // Which order we have open with have one or more updated packed item.
                this.isAnyItemPacked = true;
                this.listGrid_dataBind();
            })
            .finally(() => {
                kendo.ui.progress($("#" + this.props.windowId + "EditPackageDetails"), false);
            });
    };

    updatedPackageWeight = (packageUri) => {
        smallParcelService
            .getPackageFromMT(packageUri)
            .then((response) => {
                let result = response.data;
                _.each(this.order.PackageData, (p, i) => {
                    if (p.PackageId == result.PackageId) {
                        this.order.PackageData[i] = result;
                    }
                });
                this.listGrid_dataBind();
            })
            .finally(() => {});
    };
    onChangePackFunc = (e) => {
        if (e.target.name == "transactionLabel") {
            metaDataService.setPackAndShipTransactionLabelInMetaData(e.target.value);
        }
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.listGrid_dataBind();
            }
        );
    };

    autoPrintChangeEvent = (e) => {
        this.isPrintIconShaw = e.target.checked;
        metaDataService.setPackAndShipAutoPrintInMetaData(this.isPrintIconShaw);
        this.listGrid_dataBind();
    };
    oneQtyPackChangeEvent = (e) => {
        this.setState({
            isOneQtyPack: e.target.checked,
        });
        metaDataService.setPackAndShipOneQtyPackInMetaData(e.target.checked);
    };
    onChangeForSelectUnpack = (e) => {
        this.onSelectUnpackHandler(e.target.checked);
    };

    onSelectUnpackHandler = (isChecked) => {
        this.setState(
            {
                selectUnpack: isChecked,
                isPackSelectedBtnEnabled: isChecked,
                packSelectedBtnTooltip: "",
            },
            () => {
                var gridRef = $("#packAndShipTransactionGrid").data("kendoGrid");
                gridRef.clearSelection();
                if (this.state.isPackSelectedBtnEnabled) {
                    var data = gridRef.dataSource.data();
                    var unAssignSkuUid = [];
                    $.grep(data, (d) => {
                        if (d.PackageId == 0) {
                            unAssignSkuUid.push("[data-uid='" + d.uid + "']");
                        }
                    });
                    if (unAssignSkuUid.length == 0) {
                        this.setState({
                            selectUnpack: false,
                            isPackSelectedBtnEnabled: false,
                        });
                    } else {
                        gridRef.select(unAssignSkuUid.join(", "));
                        this.listGrid_rowClick();
                    }
                } else {
                    gridRef.clearSelection();
                }
            }
        );
    };

    checkIfOrderAlreadyInPrinting = (calledFrom) => {
        const orderIds = this.props.sharedSelectedOrderIds;
        var _searchRefunds = window.smallParcelPackNShipTransactionIds.some((i) => orderIds.includes(i.orderId));
        if (_searchRefunds) {
            this.isDuplicatePrintCalledFrom = calledFrom;
            this.setState({
                isOpenDuplicatePrintingModel: true,
            });
            return true;
        }
        return false;
    };

    onCloseDuplicatePrintingModel = () => {
        this.setState({
            isOpenDuplicatePrintingModel: false,
        });
    };

    onClickPrintAnyways = () => {
        if (window.pendo?.track) {
            var pendoTrackObj = {
                orderIds: this.props.sharedSelectedOrderIds,
                tplId: GlobalService.CurrentUser.TplId.toString(),
                tpl: GlobalService.CurrentUser.TplGuid.toString(),
            };
            window.pendo.track("doubleClickShipments", pendoTrackObj);
        }
        if (this.isDuplicatePrintCalledFrom == "ship") {
            this.onCheckToCallAutoRateShopperBeforeShip();
        } else {
            this.onPackClick("fromPackNShip");
        }
        this.onCloseDuplicatePrintingModel();
    };

    onPackClick = async (fromCalled) => {
        if (fromCalled == "fromPackNShip") {
            this.setState({
                gifPackNShipButton: true,
                packCompleteLoading: true,
                gifShipBtn: true,
            });
        } else {
            this.setState({ packCompleteLoading: true });
        }
        await this.updateScaleWeight(this.currentPackage);
        var orderUri = "/orders/" + this.state.packAndShipOrderInfo.OrderId + "{?detail,itemdetail,recalcautocharges}";
        var isApiResponseSuccess = false;
        smallParcelService
            .completePackage(orderUri)
            .then((response) => {
                if (this.isPrintIconShaw) {
                    this.autoPrintForAllPackage(fromCalled);
                } else if (fromCalled != "fromPackNShip") {
                    this.props.onResetGridSelection();
                    this.props.onClosePackAndShipModel();
                }

                if (fromCalled == "fromPackNShip") {
                    this.onCheckToCallAutoRateShopperBeforeShip(fromCalled);
                }
                isApiResponseSuccess = true;
                this.setState({
                    isPackOrPackAndShipDisabled: true,
                    packOrPackShipTooltip: this.translate("Label_Already_Packed"),
                });
            })
            .finally(() => {
                if (this._isMounted) {
                    if (fromCalled == "fromPackNShip" && isApiResponseSuccess == false) {
                        this.setState({
                            gifPackNShipButton: false,
                            packCompleteLoading: false,
                            gifShipBtn: false,
                        });
                    } else if (fromCalled !== "fromPackNShip") {
                        this.setState({ packCompleteLoading: false });
                    }
                }
            });
    };

    onPackNShipClick = () => {
        if (!this.checkIfOrderAlreadyInPrinting("packNShip")) {
            this.onPackClick("fromPackNShip");
        }
    };

    autoPrintForIndividualPackage = (packageId) => {
        this.setState({
            isPackNShipLoading: true,
            packNShipLoadingMsg: "Printing labels...",
        });

        let labelTemplateIds = [];
        if (this.state.cartonLabel != 0) {
            labelTemplateIds.push(this.state.cartonLabel);
        }
        if (this.state.transactionLabel != 0) {
            labelTemplateIds.push(this.state.transactionLabel);
        }
        let packageUri = this._getPackageFromOrder(packageId).PackageUri;
        var payload = {
            packageUri: packageUri,
            labelTemplateIds: labelTemplateIds,
        };
        smallParcelService
            .autoPrintForIndividualPackage(payload)
            .then((response) => {
                GlobalService.printDataInZplPrinter(response.data);
                GlobalService.notification("success", this.translate("Label_Labels_Printed"));
            })
            .finally(() => {
                this.setState({
                    isPackNShipLoading: false,
                    packNShipLoadingMsg: "Printing labels...",
                });
            });
    };

    autoPrintForAllPackage = (fromCalled) => {
        this.setState({
            isPackNShipLoading: true,
            packNShipLoadingMsg: "Printing labels...",
        });

        var labelTemplateIds = [];
        if (this.state.cartonLabel != 0 && fromCalled != "transactionLabelAll") {
            labelTemplateIds.push(this.state.cartonLabel);
        }
        if (this.state.transactionLabel != 0 && fromCalled != "cartonLabelAll") {
            labelTemplateIds.push(this.state.transactionLabel);
        }
        var payload = {
            orderId: this.state.packAndShipOrderInfo.OrderId,
            labelTemplateIds: labelTemplateIds,
        };
        smallParcelService
            .autoPrintForAllPackage(payload)
            .then((response) => {
                GlobalService.printDataInZplPrinter(response.data);
                this.props.onResetGridSelection();
                GlobalService.notification("success", this.translate("Label_Labels_Printed"));
            })
            .finally(() => {
                this.setState({
                    isPackNShipLoading: false,
                    packNShipLoadingMsg: "",
                });
                if (fromCalled != "fromPackNShip" && fromCalled != "transactionLabelAll" && fromCalled != "cartonLabelAll") {
                    this.props.onClosePackAndShipModel();
                }
            });
    };

    onFocusScanQtyPopup = (event) => {
        console.log("scanIconClick ", event.target);
        // let isQtyPopupScannedFocus;
        // if (this.state.isQtyPopupScannedFocus) {
        //     // setTimeout(() => {
        //         this.refs.itemQuantity.refs.myinput.focus();
        //     // }, 1000);
        //     isQtyPopupScannedFocus = false;
        // } else {
        //     isQtyPopupScannedFocus = true;
        // }
        this.setState({
            isQtyPopupScannedFocus: true,
        });
    };

    onBlurScanQtyPopup = (event) => {
        console.log("onBlurScanQtyPopup ", event.target);
        this.setState({
            isQtyPopupScannedFocus: false,
        });
    };
    closePackAndShipModel = () => {
        if (this.isSmallParcelGridRefresh) {
            this.props.onResetGridSelection();
        }

        if(this.isAnyItemPacked && this.props.sharedSelectedOrderIds.length == 1 && this.order.OrderPacked == false && !this.order.SmallParcelShipDate) {
            // Call order pushed events
            smallParcelService.orderPackEvent(this.props.sharedSelectedOrderIds[0], 'OrderPackPaused').then((response) => {
                console.log("OrderPackPaushed Events", response);
            });
        }
        this.props.onClosePackAndShipModel();
    };
    onClickGetRates = async () => {
        await this.updateScaleWeight(this.currentPackage);
        this.setState({
            showAvailableRates: true,
        });
    };
    getParcelAutoRateShopperStatus = (data, fromCalled) => {
        smallParcelService.parcelAutoRateShopperStatus(data.StatusRelativeUri).then((response) => {
            if(response.data.Done) {
                this.setState({ gifShipBtn: false });
                this.onShipClick(fromCalled);
            } else {
                setTimeout(() => {
                    this.getParcelAutoRateShopperStatus(response.data, fromCalled);
                }, 3000);
            }
        }, (err) => {
            this.setState({
                gifPackNShipButton: false,
                packCompleteLoading: false,
                gifShipBtn: false,
            });
        });
    };
    updateCarrierAndServiceInfo = () => {
        kendo.ui.progress($("#PackAndShipTransactionModel"), true);
        let orderId = this.props.sharedSelectedOrderIds[0];
        smallParcelService
            .getOrderInfo(orderId)
            .then((response) => {
                // Set Carrier Account Nickname
                this.setCarrierAccountNickname(response.accountNumber);
                var tmpCarrierServiceObj = this.getCarrierServiceName(this.state.carrierOptions, response.carrier, response.shipServiceCode);
                var tmpContactString = this.generateContactString(response);
                this.isSmallParcelGridRefresh = true;
                this.setState({
                    packAndShipContactString: tmpContactString,
                    carrier: tmpCarrierServiceObj.Carrier,
                    service: tmpCarrierServiceObj.Service,
                    packAndShipOrderInfo: Object.assign({}, response, {
                        OrderId: orderId,
                    }),
                });
            })
            .finally(() => {
                kendo.ui.progress($("#PackAndShipTransactionModel"), false);
            });
    };
    onCloseAvailableRatesModel = () => {
        this.setState({
            showAvailableRates: false,
        });
        if(this.order.SmallParcelAutoRateShopOnShip) {
            this.updateCarrierAndServiceInfo();
        }
    };
    getServiceCode = (param_service) => {
        let carrierOptions = this.state.carrierOptions;
        let serviceCode = null;

        let shipmentServices = _.find(carrierOptions, function (carriers) {
            return carriers.Carrier == param_service.Carrier;
        });

        shipmentServices = shipmentServices.ShipmentServices;
        for (let shipmentIndex = 0; shipmentIndex < shipmentServices.length; shipmentIndex++) {
            const shipment = shipmentServices[shipmentIndex];
            if (this.isEnableProvider && param_service.Carrier == "USPS") {
                if (shipment.Description.includes(param_service.Service) && shipment.PackageCode == param_service.PackageType && shipment.ShipEngineId == param_service.ServiceCode) {
                    serviceCode = shipment.Code;
                    break;
                }
            } else if (shipment.ShipEngineId == param_service.ServiceCode) {
                serviceCode = shipment.Code;
                break;
            }
        }
        return serviceCode;
    };
    getScacCode = (param_service) => {
        let tempObject = this.state.carrierOptions.filter(function (el) {
            return el.Carrier === param_service.Carrier;
        })[0];
        return (typeof tempObject !== 'undefined' && tempObject.hasOwnProperty('ScacCode') && tempObject['ScacCode'] !== null) ? tempObject['ScacCode'] : '';
    };
    generateContactString = (tmpOrderInfo) => {
        return (tmpOrderInfo.shipToName ? tmpOrderInfo.shipToName : "") + (tmpOrderInfo.shipToName && tmpOrderInfo.shipToCompany ? ", " : "") + (tmpOrderInfo.shipToCompany ? tmpOrderInfo.shipToCompany : "") + (tmpOrderInfo.shipToName || tmpOrderInfo.shipToCompany ? "\n" : "") + (tmpOrderInfo.shipToAddress ? tmpOrderInfo.shipToAddress : "") + (tmpOrderInfo.shipToAddress && tmpOrderInfo.shipToAddress2 ? ", " : "") + (tmpOrderInfo.shipToAddress2 ? tmpOrderInfo.shipToAddress2 : "") + (tmpOrderInfo.shipToAddress || tmpOrderInfo.shipToAddress2 ? "\n" : "") + (tmpOrderInfo.shipToCity ? tmpOrderInfo.shipToCity : "") + (tmpOrderInfo.shipToCity && tmpOrderInfo.shipToState ? ", " : "") + (tmpOrderInfo.shipToState ? tmpOrderInfo.shipToState : "") + (tmpOrderInfo.shipToZip ? " " + tmpOrderInfo.shipToZip : "");
    };

    getCarrierServiceName = (param_carrier_option, param_carrier, param_service_code) => {
        this.isCallAutoRateAPI = false;
        let carrierOptions = param_carrier_option;
        var tmpCarrier = this.state.carrier;
        var tmpService = this.state.service;

        if (param_carrier) {
            tmpCarrier = param_carrier;
        }
        if (!param_carrier && param_service_code) {
            tmpService = param_service_code;
            this.isCallAutoRateAPI = true;
        }
        if (param_carrier && param_service_code) {
            var serviceDescription = null;

            for (let carrierIndex = 0; carrierIndex < carrierOptions.length; carrierIndex++) {
                if (param_carrier === carrierOptions[carrierIndex].Carrier) {
                    const shipmentServices = carrierOptions[carrierIndex].ShipmentServices;
                    for (let shipmentIndex = 0; shipmentIndex < shipmentServices.length; shipmentIndex++) {
                        const shipment = shipmentServices[shipmentIndex];
                        if (shipment.Code == param_service_code) {
                            serviceDescription = shipment.Description;
                            break;
                        }
                    }
                    if (serviceDescription != null) {
                        break;
                    }
                }
            }
            tmpService = serviceDescription;
            if (serviceDescription === null) {
                tmpService = param_service_code;
                this.isCallAutoRateAPI = true;
            }
        }

        if (!param_carrier) {
            tmpCarrier = this.translate("Label_None_Selected");
        }
        if (!param_service_code) {
            tmpService = this.translate("Label_None_Selected");
        }

        return {
            Carrier: tmpCarrier,
            Service: tmpService,
        };
    };

    handleCustomPackageChange = (e) => {
        var tmpCustomPkgObj = Object.assign({}, this.state.customPackageObj, {
            [e.target.name]: e.target.value,
        });
        this.setState({
            customPackageObj: tmpCustomPkgObj,
        });
    };
    onCloseCustomPackageModel = () => {
        this.setState({ isOpenCustomPackageModel: false });
    };
    onSaveCustomDimension = () => {
        var tmpPkgCustomOption = Object.assign({}, this.pkgCustomOption);
        if (this.state.currentMeasurementStandardIsImperial) {
            tmpPkgCustomOption.LengthImperial = this.state.customPackageObj.length;
            tmpPkgCustomOption.WidthImperial = this.state.customPackageObj.width;
            tmpPkgCustomOption.HeightImperial = this.state.customPackageObj.height;
        } else {
            tmpPkgCustomOption.LengthMetric = this.state.customPackageObj.length;
            tmpPkgCustomOption.WidthMetric = this.state.customPackageObj.width;
            tmpPkgCustomOption.HeightMetric = this.state.customPackageObj.height;
        }
        this.previousSelectedPackage = this.currentPackage;
        this.currentPackage = tmpPkgCustomOption;
        this.currentPackage.isShawZeroPackageId = true;
        var tmpUniquePackageName = this.getUniquePackageName(this.translate("Label_Custom_Dimensions"));
        this.currentPackage.uniquePackageName = tmpUniquePackageName;

        for (let i = 0; i < this.state.addNumOfPackages; i++) {
            var tmpCloneCurrentPkg = _.cloneDeep(this.currentPackage);
            tmpCloneCurrentPkg.uniquePackageName = this.getUniquePackageName(this.translate("Label_Custom_Dimensions"));
            this.previousSelectedPackageArr.push(tmpCloneCurrentPkg);
            this.currentPackage = tmpCloneCurrentPkg;
        }

        this.listGrid_dataBind();
        this.onCloseCustomPackageModel();
        setTimeout(() => {
            this.setFocusOnScanAndSelectValue();
        }, 1000);
    };
    getUniquePackageName = (package_name) => {
        var customPkgCounter = _.countBy(this.previousSelectedPackageArr, (rec) => {
            return rec.Name == package_name;
        });
        var uniquePackageName = package_name.trim() + "(1)";
        if (customPkgCounter.true) {
            uniquePackageName = package_name.trim() + "(" + (customPkgCounter.true + 1) + ")";
        }
        return uniquePackageName;
    };

    onChangeQtyInput = (e) => {
        this.setState({ addNumOfPackages: e.target.value });
    };

    onSawEditCareerIcon = () => {
        if (!this.order.SmallParcelShipDate) {
            this.setState({
                isShowEditCareerIcon: true,
            });
        }
    };
    onHideEditCareerIcon = () => {
        this.setState({
            isShowEditCareerIcon: false,
        });
    };

    onSawEditContactIcon = () => {
        this.setState({
            isShowEditContactIcon: true,
        });
    };
    onHideEditContactIcon = () => {
        this.setState({
            isShowEditContactIcon: false,
        });
    };

    openEditCareerDetailModal = () => {
        this.props.editShippingInfo("packNShipEditCareer");
    };

    openEditAddressDetailModal = () => {
        this.setState({
            isOpenEditAddressDetailModal: true,
        });
    };
    onCloseEditAddressDetailModal = () => {
        this.setState({
            isOpenEditAddressDetailModal: false,
        });
    };
    setCarrierAccountNickname = (tmpAccountNumber) => {
        let tempCarrierAccountNickname = '';
        for(var i = 0; i < this.state.availableCarrierAccounts.length; i++) {
            if(tmpAccountNumber === this.state.availableCarrierAccounts[i].AccountNumber) {
                tempCarrierAccountNickname = this.state.availableCarrierAccounts[i].NickName;
            }
        }
        this.setState({ tempCarrierAccountNickname : tempCarrierAccountNickname });
    };

    render() {
        return (
            <div>
                <WmsModel id="PackAndShipTransactionModel" class="pack-and-ship-transaction-model-wrapper" title={this.translate("Label_Pack_and_Ship_Orders")} width={1200} height={800} onCloseFunc={this.props.onClosePackAndShipModel} isOpen={this.props.sharedIsPackAndShipModel} customClass="wms-model-new-ui-wrapper">
                    <div className="model-content-wrapper">
                        {this.props.sharedSelectedOrderIds.length == 1 && (
                            <div className="small-parcel-recored-wrapper">
                                {/* Left Section */}
                                <div className="small-parcel-recored-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "CustomerLabel"}>{this.translate("FindOrders_Lbl_Customer")}</th>
                                                <td data-wms-selector={this.state.selector + "CustomerValue"}>{this.state.packAndShipOrderInfo.customer}</td>
                                            </tr>
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "CarrierAccountLabel"}>{this.translate("Label_Carrier_Account")}</th>
                                                <td className="remove-white-space" data-wms-selector={this.state.selector + "CarrierAccountValue"}>{GlobalService.showFirstNChar(this.state.packAndShipOrderInfo.accountNumber, 25)}</td>
                                            </tr>
                                            {this.order.SmallParcelCarrierSelectionV2 &&
                                                <tr>
                                                    <th data-wms-selector={this.state.selector + "AccountNicknameLabel"}>{this.translate("Label_Account_Nickname")}</th>
                                                    <td data-wms-selector={this.state.selector + "AccountNicknameValue"}>{(this.state.packAndShipOrderInfo.accountNickName) ? this.state.packAndShipOrderInfo.accountNickName : this.state.tempCarrierAccountNickname}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "TransactionIdLabel"}>{this.translate("Orders_Lbl_Transaction_Id")}</th>
                                                <td data-wms-selector={this.state.selector + "TransactionIdValue"}>{this.state.packAndShipOrderInfo.OrderId}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* Middle Section */}
                                <div className="small-parcel-recored-col" onMouseEnter={this.onSawEditCareerIcon} onMouseLeave={this.onHideEditCareerIcon}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "CarrierLabel"}>{this.translate("Label_Carrier")}</th>
                                                <td data-wms-selector={this.state.selector + "CarrierValue"}>{this.state.carrier && this.state.carrier == "PitneyBowesStd" ? "Pitney Bowes Standard" : this.state.carrier}</td>
                                            </tr>
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "ServiceLabel"}>{this.translate("Label_Service")}</th>
                                                <td data-wms-selector={this.state.selector + "ServiceValue"}>{this.state.service}</td>
                                            </tr>
                                            {this.order.SmallParcelCarrierSelectionV2 &&
                                                <tr>
                                                    <th data-wms-selector={this.state.selector + "BillingTypeLabel"}>{this.translate("Label_Billing")}</th>
                                                    <td data-wms-selector={this.state.selector + "BillingTypeValue"}>{(this.state.packAndShipOrderInfo.billingCode && this.state.packAndShipOrderInfo.billingCode != null) ? this.state.packAndShipOrderInfo.billingCode : 'Unspecified'}</td>
                                                </tr>
                                            }
                                            {((this.state.packAndShipOrderInfo.overrideAccountNumber) && (this.order.SmallParcelCarrierSelectionV2) && (
                                                <tr>
                                                    <th data-wms-selector={this.state.selector + "OverrideAccountLabel"}>{this.translate("Label_Override_Account")}</th>
                                                    <td data-wms-selector={this.state.selector + "OverrideAccountNumberValue"}>{GlobalService.showFirstNChar(this.state.packAndShipOrderInfo.overrideAccountNumber, 25)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.isShowEditCareerIcon && <span className="edit-icon fa fa-pencil" onClick={this.openEditCareerDetailModal}></span>}
                                </div>
                                {/* Right Section */}
                                <div className="small-parcel-recored-col" onMouseEnter={this.onSawEditContactIcon} onMouseLeave={this.onHideEditContactIcon}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th data-wms-selector={this.state.selector + "RecipientAddressLabel"}>{this.translate("Label_Recipient_Address")}</th>
                                                <td data-wms-selector={this.state.selector + "RecipientAddressValue"}>{this.state.packAndShipContactString}</td>
                                            </tr>
                                            {this.state.packAndShipOrderInfo.warehouseInstructions &&
                                                <tr>
                                                    <th data-wms-selector={this.state.selector + "WarehouseInstructionsLabel"}>{this.translate("Label_Shipping_Warehouse_Instructions")}</th>
                                                    <td data-wms-selector={this.state.selector + "WarehouseInstructionsValue"}>{GlobalService.showFirstNChar(this.state.packAndShipOrderInfo.warehouseInstructions, 50)}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {this.state.isShowEditContactIcon && <span className="edit-icon fa fa-pencil" onClick={this.openEditAddressDetailModal}></span>}
                                </div>
                            </div>
                        )}

                        <WmsCollapse id={"PackAndShipSmartParcel"} headerLabel={this.translate("Label_Smart_Parcel")} showCollapseText={false} showCollapseIcon={this.state.isSmartParcelEnabled && this.state.isSmartPackEnabled} isExpanded={this.state.isSmartParcelEnabled} isDisabled={!this.state.isSmartParcelEnabled} isShowTooltip={!this.state.isSmartParcelEnabled} tooltipText={this.translate("Label_Setting_Disable_Tooltip")}>
                            <WmsDropdown id="PackAndShiProcessAs" name="processAs" label={this.translate("Label_ProcessAs")} wmsSelector={this.state.selector + "ProcessAs"} value={this.state.packAdnShipObj.processAs} onChangeFunc={this.onChangeFunc} options={this.processAsOption} textField="description" valueField="value" extraWrapperClass="pack-and-ship-process" />

                            <WmsDropdown
                                id="PackAndShiLabelFormat"
                                name="labelFormat"
                                label={this.state.packAdnShipObj.processAs == "High Volume" ? this.translate("Label_Label_Reprint_Format") : this.translate("Label_Label_Format")}
                                wmsSelector={this.state.selector + "LabelFormat"}
                                value={this.state.packAdnShipObj.labelFormat}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.packAdnShipObj.processAs == "High Volume" ? this.labelFormateOptionForHvp : this.labelFormateOption}
                                textField="description"
                                valueField="value"
                                //disabled={this.state.packAdnShipObj.processAs == 'High Volume'}
                                //extraWrapperClass={"pack-and-ship-label-format" + (this.state.packAdnShipObj.processAs == 'High Volume' ? ' disable-element': '') } />
                                extraWrapperClass="pack-and-ship-label-format"
                            />

                            <WmsDropdown id="PackAndShiReturnLabel" name="returnLabel" label={this.translate("Label_Return_Label")} wmsSelector={this.state.selector + "ReturnLabel"} value={this.state.packAdnShipObj.returnLabel} onChangeFunc={this.onChangeFunc} options={this.returnLabelOptions} textField="description" valueField="value" disabled={this.state.packAdnShipObj.processAs == "High Volume"} extraWrapperClass={"pack-and-ship-return-label" + (this.state.packAdnShipObj.processAs == "High Volume" ? " disable-element" : "")} />
                            {this.props.sharedSelectedOrderIds.length == 1 && <WmsButton label={this.translate("Lable_Get_Rates")} wmsSelector={this.state.selector + "GetRates"} onClickFunc={this.onClickGetRates} disabled={this.state.isAnyItemUnpacked} showToolTip={true} tooltipText={this.state.isAnyItemUnpacked ? this.translate("Label_Fully_Packed_Disable_Note_Gate_Rate") : ""} icon="far fa-list-alt" buttonType="green" parentClassName={"get-rates-button"} />}
                            {this.state.packAdnShipObj.processAs == "Standard" && <WmsDropdown id="PackAndShiComboLabelTemplate" name="comboLabelTemplate" label={this.translate("Label_Label_Template")} wmsSelector={this.state.selector + "ComboLabelTemplate"} value={this.state.packAdnShipObj.comboLabelTemplate} onChangeFunc={this.onChangeFunc} options={this.state.menuOptions[0].Reports} textField="Name" valueField="Name" extraWrapperClass="pack-and-ship-combo-template" />}
                            {this.state.packAdnShipObj.processAs == "High Volume" && <WmsInput id="batchname" inputName="batchname" wmsSelector={this.state.selector + "batchname"} value={this.state.packAdnShipObj.batchname} onChangeFunc={this.onChangeFunc} label={this.translate("Label_Batch_Name")} extraWrapperClass="pack-and-ship-batchname" />}
                            {this.state.isIntegratedPackingSlips && (
                                <div className="packing-slip-wrapper">
                                    <WmsDropdown
                                        id="smallParcelPackSlip"
                                        name="SmallParcelPackSlip"
                                        label={this.translate("Label_Package_Slip_Type")}
                                        wmsSelector={this.state.selector + "SmallParcelPackSlip"}
                                        value={this.state.packAdnShipObj.SmallParcelPackSlip}
                                        onChangeFunc={this.onChangeFunc}
                                        options={this.state.optionsPackingReports}
                                        valueField="TemplatedRunner"
                                        textField="Name"
                                        parentClassName="form_group"
                                        extraWrapperClass={'package-slip-type'}
                                    />
                                    <WmsDropdown
                                        id="smallParcelAddressTemplate"
                                        name="SmallParcelAddressTemplate"
                                        label={this.translate("Label_Packing_Slip_Address_Configuration")}
                                        wmsSelector={this.state.selector + "SmallParcelAddressTemplate"}
                                        value={this.state.packAdnShipObj.SmallParcelAddressTemplate}
                                        onChangeFunc={this.onChangeFunc}
                                        options={this.state.optionsTemplateReports}
                                        valueField="Id"
                                        textField="Name"
                                        parentClassName="form_group"
                                        extraWrapperClass={'packing-slip-address-configuration'}
                                        disabled={!this.state.packAdnShipObj.SmallParcelPackSlip}
                                    />
                                </div>
                            )}
                        </WmsCollapse>

                        <WmsCollapse id={"PackAndShipSmartPack"} headerLabel={this.translate("Label_Smart_Pack")} showCollapseText={false} showCollapseIcon={this.state.isSmartParcelEnabled && this.state.isSmartPackEnabled} isExpanded={this.state.isSmartPackEnabled} isDisabled={!this.state.isSmartPackEnabled} isShowTooltip={!this.state.isSmartPackEnabled} tooltipText={this.props.sharedSelectedOrderIds.length > 1 ? this.translate("Label_Setting_Disable_Multiple_Order_Tooltip") : this.translate("Label_Setting_Disable_Tooltip")}>
                            <div className="inline-elements">
                                <div className={(this.order.SmallParcelShipDate ? "disabled-add-pkg " : "") + "add-package-wrapper"}>
                                    <label className="pkg-option-label">{this.translate("Label_Add_a_Package")}</label>
                                    <div className="pkg-elements-wrapper">
                                        <label className="qty-label">{this.translate("Label_Qty")}</label>
                                        <WmsInput id={this.state.selector + "QtyInput"} extraWrapperClass="qty-input-wrapper" inputName={"addNumOfPackages"} wmsSelector={this.state.selector + "QtyInput"} label={""} value={this.state.addNumOfPackages} onChangeFunc={this.onChangeQtyInput} valueType={"number"} />
                                        <WmsComboBox id="PackAndShiAddaPackage" name="addaPackage" label={this.translate("Label_Add_a_Package")} wmsSelector={this.state.selector + "AddaPackage"} value={this.state.selectedPackage} onChangeFunc={this.onPackageChange} options={this.state.packagesOptions} placeholder={this.translate("Label_Select_Package_Option")} valueField="PackageDefId" textField="Name" parentClassName="pack-and-ship-add-package" />
                                    </div>
                                </div>

                                <WmsDropdown id="PackAndShiCartonLabel" name="cartonLabel" label={this.translate("Label_Carton_Label")} wmsSelector={this.state.selector + "CartonLabel"} value={this.state.cartonLabel} onChangeFunc={this.onChangePackFunc} options={this.state.cartonLabelOptions} textField="Name" valueField="LabelTemplateId" extraWrapperClass="pack-and-ship-carton-label" />

                                <WmsDropdown id="PackAndShiTransactionLabel" name="transactionLabel" label={this.translate("Label_Transaction_Label")} wmsSelector={this.state.selector + "TransactionLabel"} value={this.state.transactionLabel} onChangeFunc={this.onChangePackFunc} options={this.state.transactionLabelOptions} textField="Name" valueField="LabelTemplateId" extraWrapperClass="pack-and-ship-transaction-label" />

                                <WmsCheckBox id={"autoPrint"} name={"autoPrint"} wmsSelector={this.state.selector + "autoPrint"} onChangeFunc={this.autoPrintChangeEvent} label={this.translate("Label_Auto_Print_Labels")} value={this.isPrintIconShaw} checked={this.isPrintIconShaw} />
                            </div>

                            <div className="scan-key-wrapper">
                                <div className="first-section">
                                    <WmsInput
                                        id={this.state.selector + "scanGridKey"}
                                        extraWrapperClass="full-width-input"
                                        inputName={"scanGridKey"}
                                        wmsSelector={this.state.selector + "scanGridKey"}
                                        label={this.isAllowDuplicatedUPC ? this.translate("Label_Pack_a_Line_Item_Without_UPC") : this.translate("Label_Pack_a_Line_Item")}
                                        value={this.state.scanGridKey}
                                        onChangeFunc={this.handleScanGridKeyChange}
                                        onIconClick={this.handleScanGridKeyChange}
                                        showIconAfterInput={true}
                                        icon={this.state.scanInputLoading ? "fa-spinner fa-pulse" : ""}
                                        // isAutoFocus={true}
                                        ref="scanGridKey"
                                    />
                                    <WmsButton label="" wmsSelector={this.state.selector + "ScanKeyAddButton"} onClickFunc={this.onScanValueEnter} icon="fa-plus" disabled={!this.state.scanGridKey} buttonType="green" parentClassName={"package-scan-add"} />
                                </div>
                                <div>
                                    <div className="chk-btn-wrapper">
                                        <WmsCheckBox id={"selectUnpack"} name={"selectUnpack"} wmsSelector={this.state.selector + "selectUnpack"} onChangeFunc={this.onChangeForSelectUnpack} label={this.translate("Label_Select_Unpacked_Items")} value={this.state.selectUnpack} checked={this.state.selectUnpack} disabled={this.order.SmallParcelDisableClickToPack || !this.state.isAnyItemUnpacked} />

                                        <WmsCheckBox id={"oneQtyPack"} name={"oneQtyPack"} wmsSelector={this.state.selector + "oneQtyPack"} onChangeFunc={this.oneQtyPackChangeEvent} label={this.translate("Label_Qty_One_Pack")} value={this.state.isOneQtyPack} checked={this.state.isOneQtyPack} parentClassName="qty-chk-wrapper" showToolTip={true} tooltipText={this.translate("Label_Qty_One_Pack_Tooltip")} tooltipShowAfter={300} />

                                        <WmsButton label={this.translate("Label_Pack_Selected")} wmsSelector={this.state.selector + "PackSelected"} onClickFunc={this.onClickPackSelectedItem} disabled={!this.state.isPackSelectedBtnEnabled || this.state.packSelectedBtnTooltip != "" || this.order.SmallParcelDisableClickToPack} showToolTip={this.state.packSelectedBtnTooltip != ""} tooltipText={this.state.packSelectedBtnTooltip} icon="fas fa-box-open" parentClassName={"pack-all-selected"} />

                                        <WmsButton label={this.translate("Label_Delete_All_Packages")} wmsSelector={this.state.selector + "DeleteAllPackage"} onClickFunc={this.onDeleteAllPackage} icon="fa-trash" buttonType="red" disabled={this.state.disableDeleteAll || (this.order.SmallParcelShipDate ? true : false)} showToolTip={this.order.SmallParcelShipDate ? true : false} tooltipText={this.translate("Label_Already_Shipped_Tooltip")} parentClassName={"package-delete-all"} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {this.state.isDeviceConnect && (
                                    <div className="scale-weight-text">
                                        <label>{this.translate("Label_Current_Package_Weight")}</label>
                                        <span>{this.state.scaleChangeWeight + " " + (this.state.currentMeasurementStandardIsImperial ? "lbs" : "kg")}</span>
                                    </div>
                                )}
                            </div>

                            <VirtualGroupGrid
                                ref="packAndShipTransactionGrid"
                                wmsSelector={this.state.selector + "Grid"}
                                gridKeySelection={"RowNumber"}
                                groupKeyName={"val"}
                                staticGridData={this.state.smallPackGridData}
                                gridName={"packAndShipTransactionGrid"}
                                filterText={""}
                                columns={PackAndShipGridConfig.getPackAndShipGridColumns().columns}
                                id={"packAndShipTransactionGrid"}
                                showScanKeyInput={true}
                                scanKeyLabel={this.translate("Label_Pack_a_Line_Item")}
                                onSelection={this.onGridSelection}
                                schema={PackAndShipGridConfig.getPackAndShipGridSchema().schema}
                                onClickGroupHeader={this.listGrid_groupClick}
                                onDataBound={this.onGridDataBound}
                                onColumnReorder={this.onColumnReorder}
                                onSort={this.onSort}
                                metaGridConfig={metaDataService.getPackAndShipGridOptionInMetaData()}
                                sortOptions={this.state.gridSortOptions}
                            />
                        </WmsCollapse>
                    </div>

                    <div className="footer-btn-holder">
                        <WmsButton label={this.translate("Label_Close")} wmsSelector={this.state.selector + "PickTicketExit"} onClickFunc={this.closePackAndShipModel} disabled={false} loading={false} buttonType="orange" icon={"fa-times-circle-o"} />
                        {this.state.isSmartPackEnabled && <WmsButton id={this.state.selector + "Pack"} label={this.translate("Label_Pack")} wmsSelector={this.state.selector + "Pack"} onClickFunc={this.onPackClick} loading={this.state.packCompleteLoading} disabled={this.state.isPackOrPackAndShipDisabled} showToolTip={true} tooltipText={this.state.packOrPackShipTooltip} icon={"fas fa-box-open"} />}
                        {this.state.isSmartParcelEnabled && <WmsButton id={"PackAndSshipTransaction" + this.props.windowId} label={this.translate("Label_Ship")} wmsSelector={this.state.selector + "Finish"} onClickFunc={this.onShipBtnClick} disabled={this.state.gifShipBtn || !this.state.isSmartParcelEnabled || (this.order.SmallParcelShipDate ? true : false) || this.state.isAnyItemUnpacked} showToolTip={this.order.SmallParcelShipDate || this.state.isAnyItemUnpacked ? true : false} tooltipText={this.state.isAnyItemUnpacked ? this.translate("Label_Fully_Packed_Disable_For_Ship_Note") : this.translate("Label_Already_Shipped_Tooltip")} loading={this.state.gifShipBtn} icon={"fa-dolly"} />}
                        {this.state.isSmartParcelEnabled &&
                            this.state.isSmartPackEnabled && ( // smart parcel section disable condition
                                <WmsButton id={this.state.selector + "PackAndShip"} label={this.translate("Label_Pack_And_Ship")} wmsSelector={this.state.selector + "PackAndShip"} disabled={this.state.isPackOrPackAndShipDisabled} showToolTip={true} tooltipText={this.state.packOrPackShipTooltip} onClickFunc={this.onPackNShipClick} loading={this.state.gifPackNShipButton} icon={"fa-shipping-fast"} />
                            )}
                        {this.state.isPackNShipLoading && (
                            <div className="div-packnship-loading-footer" id="div-packnship-loading-footer">
                                <i className="fa fa-spinner fa-spin"></i>&nbsp;
                                {this.state.packNShipLoadingMsg}
                            </div>
                        )}
                    </div>
                </WmsModel>
                {this.state.isOpenAddQuantity && (
                    <WmsModel id={this.props.windowId + "AddItemQuantity"} title={this.translate("Label_Packed_Quantity")} width={450} height={175} class="add-item-quantity-wrapper" onCloseFunc={this.closeQuantityModal} removeCloseAnimation={true} removeOpenAnimation={true} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenAddQuantity}>
                        <div className="model-content-wrapper text-align-center">
                            <div className="sku-detail-wrapper">
                                <ul>
                                    {this.content.Sku && (
                                        <li>
                                            <label>Sku:</label>
                                            <span>{this.content.Sku}</span>
                                        </li>
                                    )}
                                    {this.content.Qualifier && (
                                        <li>
                                            <label>Qualifier:</label>
                                            <span>{this.content.Qualifier}</span>
                                        </li>
                                    )}
                                    {this.content.Description && (
                                        <li>
                                            <label>Desc:</label>
                                            <span>{this.content.Description}</span>
                                        </li>
                                    )}
                                    {this.content.Lot && (
                                        <li>
                                            <label>Lot:</label>
                                            <span>{this.content.Lot}</span>
                                        </li>
                                    )}
                                    {this.content.ExpirationDate && (
                                        <li>
                                            <label>Expr:</label>
                                            <span>{kendo.toString(kendo.parseDate(this.content.ExpirationDate), "MMM dd yyyy")}</span>
                                        </li>
                                    )}
                                    {this.content.Serial && (
                                        <li>
                                            <label>S/n:</label>
                                            <span>{this.content.Serial}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <p>{this.translate("Label_Quantity_Remaining")}</p>
                            <h1>{this.state.remainingQuantity % 1 !== 0 ? this.state.remainingQuantity.toFixed(3) : this.state.remainingQuantity}</h1>
                            <hr />
                            <div className="scan-key-wrapper">
                                <div className="scan-key-not-found">{this.state.isScanPackedNotAvailable ? this.translate("Label_Costomer_Record_Not_Found") : ""}</div>
                            </div>
                            <div className={(this.state.itemQuantity && this.state.invalidValue ? "invalid-error " : "") + "quantity-wrapper"}>
                                <i className={(this.state.itemQuantity <= 0 ? "disable-btn " : "") + "fas fa-minus"} onClick={this.onQtyDecrement} data-wms-selector="packedQuantityMinus"></i>
                                <WmsInput
                                    id="itemQuantity"
                                    inputName="itemQuantity"
                                    wmsSelector={this.state.selector + "itemQuantity"}
                                    value={this.state.itemQuantity}
                                    disabled={false}
                                    onChangeFunc={this.onItemQtyChangeFunc}
                                    label={this.translate("Label_Quantity")}
                                    regularExp={this.state.quantityRegx} // change number and float input conditionally
                                    extraWrapperClass="pack-and-ship-itemQuantity"
                                    ref="itemQuantity"
                                />
                                <i className={(this.state.remainingQuantity == 0 ? "disable-btn " : "") + "fas fa-plus"} onClick={this.onQtyIncrement} data-wms-selector="packedQuantityPlus"></i>
                                <label className={(this.state.isQtyPopupScannedFocus ? "scanned-focused " : "") + (this.state.isQtyPopupScanDetected ? "scanned-detected " : "") + "scan-inputlabel"} htmlFor={this.state.selector + "scanPackedQty"}>
                                    <i className="fas fa-qrcode"></i>
                                    {/* onClick={this.scanIconClick} */}
                                    <input type={"text"} name={"scanPackedQty"} ref="scanPackedQty" value={this.state.scanPackedQty} onChange={this.handleScanGridKeyChange} onBlur={this.onBlurScanQtyPopup} onFocus={this.onFocusScanQtyPopup} id={this.state.selector + "scanPackedQty"} />
                                </label>
                            </div>
                            <span className={(this.state.itemQuantity && this.state.invalidValue ? "" : "message-opacity ") + "error-message"}>{this.translate("Label_Max_Qty_Warning").replace("{remainQty}", this.content.Remainder)}</span>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.closeQuantityModal} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Next")} wmsSelector={this.state.selector + "Next"} onClickFunc={this.qtyNext} disabled={this.state.invalidValue} loading={false} buttonType="green" icon={"fa-caret-right"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenSerialPopup && (
                    <WmsModel id={this.props.windowId + "SerialPopup"} title={this.translate("Label_Serial_Numbers")} width={350} height={175} removeCloseAnimation={true} class="serial-number-popup-wrapper" onCloseFunc={this.closeSerialPopup} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenSerialPopup}>
                        <div className="model-content-wrapper text-align-center">
                            <p className="serial-reminder-label">
                                {this.content.isSerialUnique ? "Unique " : ""}
                                {this.translate("Label_Unique_Serial_Numbers")}: {this.content.Sku}
                            </p>
                            <h1>{this.state.packSerialQty % 1 !== 0 ? parseFloat(this.state.packSerialQty).toFixed(3) : this.state.packSerialQty} </h1>
                            <hr />
                            <div className="serial-input-add-wrapper">
                                <WmsInput id={this.state.selector + "serialNumber"} inputName={"enteredSerialNumber"} wmsSelector={this.state.selector + "serialNumber"} label={(this.content.isSerialUnique ? "Unique " : "") + this.translate("Label_Unique_Serial_Number")} value={this.state.enteredSerialNumber} onChangeFunc={this.handleScanGridKeyChange} isAutoFocus={true} disabled={this.isSerialNumberDisableToAddNew} ref="serialNumber" />
                                <WmsButton label={""} wmsSelector={this.state.selector + "AddSerialNumber"} onClickFunc={this.onEnterSerialInput} icon="fa-plus" disabled={!this.state.enteredSerialNumber} />
                            </div>
                            <hr />
                            <div className="provide-serial-wrapper">
                                <h4>{this.translate("Label_Provided_Serial_Numbers")}:</h4>
                                <ul>
                                    {this.state.serials.map((serial, index) => (
                                        <li key={index}>{serial}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.closeSerialPopup} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Next")} wmsSelector={this.state.selector + "Next"} onClickFunc={this.onNextSerialPopup} disabled={!this.state.isEnabledNextOnSerialPopup} loading={false} buttonType="green" icon={"fa-caret-right"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenEditItemQtyModel && (
                    <WmsModel id={this.props.windowId + "EditPackedItem"} title={this.translate("Label_Edit_Packed_Item")} width={650} height={300} class="edit-packed-item-wrapper" onCloseFunc={this.closePackItem} removeCloseAnimation={true} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenEditItemQtyModel}>
                        <div className="model-content-wrapper text-align-center">
                            <WmsCollapse id={"EditPackedItem"} headerLabel={this.translate("Label_Edit_Packed_Item")} showCollapseText={false} showCollapseIcon={false}>
                                <WmsDropdown id="PackedIn" name="selectPackedIn" label={this.translate("Label_Packed_In")} wmsSelector={this.state.selector + "PackedIn"} value={this.state.selectPackedIn} onChangeFunc={this.itemPackSelectChange} options={this.state.editPackedInOptions} valueField="value" textField="text" />
                            </WmsCollapse>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.closePackItem} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Unpack_Sku")} wmsSelector={this.state.selector + "UnpackSku"} onClickFunc={this.unpackItem} disabled={false} loading={false} buttonType="yellow" icon={"fa-dropbox"} />
                            <WmsButton label={this.translate("Label_Save")} wmsSelector={this.state.selector + "Save"} onClickFunc={this.saveItem} disabled={this.state.packedInSaveDisable} loading={false} icon="fa-floppy-o" />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenEditPackageDetailsModel && (
                    <WmsModel id={this.props.windowId + "EditPackageDetails"} title={this.translate("Label_Edit_Package_Details")} width={650} height={300} removeCloseAnimation={true} class="edit-package-detail-wrapper" onCloseFunc={this.closePackGroup} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenEditPackageDetailsModel}>
                        <div className="model-content-wrapper text-align-center">
                            <WmsCollapse id={"serviceChargeTrigger"} headerLabel={this.translate("Label_Package_Details")} showCollapseText={false} showCollapseIcon={true}>
                                <WmsDropdown id="editSelectedPackageDefId" name="editSelectedPackageDefId" label={this.translate("Label_Selected_Package")} wmsSelector={this.state.selector + "editSelectedPackageDefId"} value={this.state.editSelectedPackageDefId} onChangeFunc={this.handleEditPackageChange} options={this.state.packagesOptions} textField="Name" valueField="PackageDefId" extraWrapperClass="pack-and-ship-package-options" />
                                <WmsInput id="editPackageWeight" inputName="editPackageWeight" wmsSelector={this.state.selector + "editPackageWeight"} value={this.state.editPackageWeight} onChangeFunc={this.handleEditPackageChange} label={this.translate("Label_Package_Weight_Lbs") + (this.state.currentMeasurementStandardIsImperial ? " (lbs)" : " (kg)")} extraWrapperClass={(this.state.originalPackageWeight != this.state.editPackageWeight && this.state.isDeviceConnect == false ? "" : "reset-icon-opacity ") + "pack-and-ship-weight"} disabled={this.state.isDeviceConnect} showIconAfterInput={true} onIconClick={this.state.isDeviceConnect ? () => {} : this.onResetIconClick} icon="fa-refresh" />
                                {this.state.isCustomDimensionPackSelect && (
                                    <div className="customer-dimensions-wrapper">
                                        <div className="dimension-wrapper">
                                            <WmsInput id="length" inputName={"editPackageLength"} label={this.translate("Label_Length") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.selector + "Length"} onChangeFunc={this.handleEditPackageChange} value={this.state.editPackageLength} extraWrapperClass="length-wrapper" required={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                                            <WmsInput id="width" inputName={"editPackageWidth"} label={this.translate("Label_Width") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.selector + "Width"} onChangeFunc={this.handleEditPackageChange} value={this.state.editPackageWidth} extraWrapperClass="width-wrapper" required={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                                            <WmsInput id="height" inputName={"editPackageHeight"} label={this.translate("Label_Height") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.Selector + "Height"} onChangeFunc={this.handleEditPackageChange} value={this.state.editPackageHeight} extraWrapperClass="height-wrapper" required={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />
                                        </div>
                                    </div>
                                )}
                            </WmsCollapse>

                            <WmsCollapse id={"referenceFields"} headerLabel={this.translate("Label_Reference_Fields")} showCollapseText={false} showCollapseIcon={true}>
                                <div className="reference-fields-wrapper">
                                    <WmsInput id="reference1" inputName="reference1" wmsSelector={this.state.selector + "reference1"} value={this.state.reference1} onChangeFunc={this.handleEditReferenceFieldChange} label={this.translate("Label_Reference_Field_1")} disabled={false} showIconAfterInput={false} onIconClick={() => {}} />

                                    <WmsInput id="reference2" inputName="reference2" wmsSelector={this.state.selector + "reference2"} value={this.state.reference2} onChangeFunc={this.handleEditReferenceFieldChange} label={this.translate("Label_Reference_Field_2")} disabled={false} showIconAfterInput={false} onIconClick={() => {}} />

                                    <WmsInput id="reference3" inputName="reference3" wmsSelector={this.state.selector + "reference3"} value={this.state.reference3} onChangeFunc={this.handleEditReferenceFieldChange} label={this.translate("Label_Reference_Field_3")} disabled={false} showIconAfterInput={false} onIconClick={() => {}} />
                                </div>
                            </WmsCollapse>

                            <WmsCollapse id={"packageDescription"} headerLabel={this.translate("Label_Package_Description")} showCollapseText={false} showCollapseIcon={true}>
                                <div className="reference-fields-wrapper">
                                    <WmsInput id="packageContentDescription" inputName="packageContentDescription" wmsSelector={this.state.selector + "packageContentDescription"} value={this.state.packageContentDescription} label={this.translate("Label_Package_Content_Description")} onChangeFunc={this.handleEditPackageContentDescriptionChange} />
                                </div>
                            </WmsCollapse>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.closePackGroup} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Save")} wmsSelector={this.state.selector + "Save"} onClickFunc={this.updatePackage} disabled={this.state.packGroupSaveDisable || !this.state.editPackageHeight || !this.state.editPackageLength || !this.state.editPackageWidth} loading={false} icon="fa-floppy-o" />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenDeleteConfirmModel && (
                    <WmsModel id={this.props.windowId + "confirmDeletePackage"} title={this.translate("Label_Confirm_Delete_Package")} width={600} height={200} removeCloseAnimation={true} class="confirm-remove-package-wrapper" onCloseFunc={this.onCloseDeleteConfirmationModel} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenDeleteConfirmModel}>
                        <div className="model-content-wrapper">
                            <label
                                className="confirm-remove-msg"
                                dangerouslySetInnerHTML={{
                                    __html: this.translate("Label_Delete_Package_Confirm").replace("{packagename}", '"' + this.state.selectedPackageName + '"'),
                                }}
                            ></label>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.onCloseDeleteConfirmationModel} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Delete")} wmsSelector={this.state.selector + "Delete"} onClickFunc={this.deleteConfirmPackage} buttonType="red" disabled={false} loading={false} icon={"fa-trash"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenConfirmDeleteAllPopup && (
                    <WmsModel id={this.props.windowId + "confirmDeletePackage"} title={this.translate("Label_Confirm_Delete_Package")} width={600} height={200} removeCloseAnimation={true} class="confirm-remove-all-package-wrapper" onCloseFunc={this.onCloseDeleteAllConfirmationModel} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenConfirmDeleteAllPopup}>
                        <div className="model-content-wrapper">
                            <label className="confirm-remove-msg">{this.translate("Label_Delete_All_Package_Confirm")}</label>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "DeleteAllCancel"} onClickFunc={this.onCloseDeleteAllConfirmationModel} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Delete")} wmsSelector={this.state.selector + "DeleteAllDelete"} onClickFunc={this.deleteAllConfirm} buttonType="red" disabled={false} loading={this.state.deleteAllLoading} icon={"fa-trash"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenTrackByInfoMOdel && (
                    <WmsModel id={this.props.windowId + "trackByInfo"} title={this.translate("Label_Verify_Track_Bys")} width={400} height={200} removeCloseAnimation={true} class="verify-track-by-wrapper" onCloseFunc={this.onCloseVerifyTrackByModel} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenTrackByInfoMOdel}>
                        <div className="model-content-wrapper">
                            <ul id="trackby_list">
                                {this.state.trackByModelInfo.map((item, index) => (
                                    <li key={"InforKey_" + index}>
                                        <strong>{item.text + " :"}</strong>
                                        {item.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.onCloseVerifyTrackByModel} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Select_Confirm")} wmsSelector={this.state.selector + "Confirm"} onClickFunc={this.onConfirmVerifyTrackByModel} disabled={false} loading={false} icon={"fa-check-circle"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.showAvailableRates && <AvailableRates isAvailableRatesModelOpen={this.state.showAvailableRates} updateCarrierAndServiceInfo={this.updateCarrierAndServiceInfo} onCloseAvailableRatesModel={this.onCloseAvailableRatesModel} onGetServiceCode={this.getServiceCode} onGetScacCode={this.getScacCode} windowId={this.props.windowId} sharedSelectedOrderIds={this.props.sharedSelectedOrderIds} customerId={this.state.packAndShipOrderInfo.customerId} />}
                {this.state.isOpenCustomPackageModel && (
                    <WmsModel id={this.props.windowId + "CustomPackage"} title={this.translate("Label_Add_Custom_Package")} width={700} height={200} onCloseFunc={this.onCloseCustomPackageModel} customClass="wms-model-new-ui-wrapper custom-package-model-wrapper" isOpen={this.state.isOpenCustomPackageModel}>
                        <div className="model-content-wrapper">
                            <WmsCollapse id={"dimensionAndWeight"} headerLabel={this.translate("Label_Package_Dimensions_And_Weight")} showCollapseText={false} showCollapseIcon={false}>
                                <div className="dimension-input-wrapper">
                                    <WmsInput id="length" inputName={"length"} label={this.translate("Label_Length") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.selector + "Length"} onChangeFunc={this.handleCustomPackageChange} value={this.state.customPackageObj.length} extraWrapperClass="length-wrapper" required={true} isAutoFocus={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                                    <WmsInput id="width" inputName={"width"} label={this.translate("Label_Width") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.selector + "Width"} onChangeFunc={this.handleCustomPackageChange} value={this.state.customPackageObj.width} extraWrapperClass="width-wrapper" required={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                                    <WmsInput id="height" inputName={"height"} label={this.translate("Label_Height") + " (" + (this.state.currentMeasurementStandardIsImperial ? this.translate("Label_Inch_Abbrev_Wihtout_dot") : this.translate("Label_Centimeter_Abbrev_Wihtout_dot")) + ")"} wmsSelector={this.state.Selector + "Height"} onChangeFunc={this.handleCustomPackageChange} value={this.state.customPackageObj.height} extraWrapperClass="height-wrapper" required={true} regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />
                                </div>
                            </WmsCollapse>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Close")} wmsSelector={this.state.selector + "Close"} onClickFunc={this.onCloseCustomPackageModel} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Save")} wmsSelector={this.state.selector + "save"} onClickFunc={this.onSaveCustomDimension} disabled={!this.state.customPackageObj.length || !this.state.customPackageObj.width || !this.state.customPackageObj.height} loading={false} icon="fa-floppy-o" buttonType="green" />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenScanKeyNotAvailableModel && (
                    <WmsModel id={this.props.windowId + "ScanKeyNotAvailableModel"} title={this.translate("Label_Not_valid_Scan_Title")} width={600} height={200} removeCloseAnimation={true} onCloseFunc={this.onCloseScanKeyNotAvailableModel} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenScanKeyNotAvailableModel}>
                        <div className="model-content-wrapper">{this.scanNotFoundMsg}</div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Close")} wmsSelector={this.state.selector + "Close"} onClickFunc={this.onCloseScanKeyNotAvailableModel} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenDuplicatePrintingModel && (
                    <WmsModel id={this.props.windowId + "DuplicatePrintingModel"} title={this.translate("Label_Duplicate_Printing_Model")} width={600} height={200} removeCloseAnimation={true} onCloseFunc={this.onCloseDuplicatePrintingModel} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenDuplicatePrintingModel}>
                        <div className="model-content-wrapper">{"The order was already sent and printing again may cause multiple charges based on carrier fees"}</div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Close")} wmsSelector={this.state.selector + "Close"} onClickFunc={this.onCloseDuplicatePrintingModel} disabled={false} loading={false} buttonType="orange" icon={"fa-close"} />
                            <WmsButton label={this.translate("Print Anyways")} wmsSelector={this.state.selector + "PrintingLabels"} onClickFunc={this.onClickPrintAnyways} disabled={false} loading={false} buttonType="orange" icon={"fa-print"} />
                        </div>
                    </WmsModel>
                )}
                {this.state.isOpenEditAddressDetailModal && <EditAddressDetail isOpenEditAddressDetailModal={this.state.isOpenEditAddressDetailModal} onCloseEditAddressDetailModal={this.onCloseEditAddressDetailModal} sharedSelectedOrderIds={this.props.sharedSelectedOrderIds} updateAddressInfo={this.updateCarrierAndServiceInfo} />}
                {this.state.isOpenAddNonSkuQuantityModal && (
                    <WmsModel id={this.props.windowId + "AddNonSkuItemQuantity"} title={this.translate("Label_Packed_Quantity")} width={450} height={175} class="add-item-quantity-wrapper" onCloseFunc={this.closeAddNonSkuQuantityModal} removeCloseAnimation={true} removeOpenAnimation={true} customClass="wms-model-new-ui-wrapper" isOpen={this.state.isOpenAddNonSkuQuantityModal}>
                        <div className="model-content-wrapper text-align-center">
                            <div className="sku-detail-wrapper">
                                <ul>
                                    {this.nonSkuContent.Sku && (
                                        <li>
                                            <label>Sku:</label>
                                            <span>{this.nonSkuContent.Sku}</span>
                                        </li>
                                    )}
                                    {this.nonSkuContent.Description && (
                                        <li>
                                            <label>Desc:</label>
                                            <span>{this.nonSkuContent.Description}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="quantity-wrapper">
                                <i className={(this.state.nonSkuItemQuantity == 0 ? "disable-btn " : "") + "fas fa-minus"} onClick={this.onNonSkuQtyDecrement} data-wms-selector="packedNonSkuQuantityMinus"></i>
                                <WmsInput
                                    id="nonSkuItemQuantity"
                                    inputName="nonSkuItemQuantity"
                                    wmsSelector={this.state.selector + "nonSkuItemQuantity"}
                                    value={this.state.nonSkuItemQuantity}
                                    disabled={false}
                                    onChangeFunc={this.onNonSkuItemQtyChangeFunc}
                                    label={this.translate("Label_Quantity")}
                                    regularExp={this.state.quantityRegx} // change number and float input conditionally
                                    extraWrapperClass="pack-and-ship-itemQuantity"
                                    ref="nonSkuItemQuantity"
                                />
                                <i className={(this.state.nonSkuItemQuantity < 0 ? "disable-btn " : "") + "fas fa-plus"} onClick={this.onNonSkuQtyIncrement} data-wms-selector="packedNonSkuQuantityPlus"></i>
                                <label className={(this.state.isQtyPopupScannedFocus ? "scanned-focused " : "") + (this.state.isQtyPopupScanDetected ? "scanned-detected " : "") + "scan-inputlabel"} htmlFor={this.state.selector + "scanNonSkuPackedQty"}>
                                    <i className="fas fa-qrcode"></i>
                                    <input type={"text"} name={"scanNonSkuPackedQty"} ref="scanNonSkuPackedQty" value={this.state.scanNonSkuPackedQty} onChange={this.handleScanGridKeyChange} onBlur={this.onBlurScanQtyPopup} onFocus={this.onFocusScanQtyPopup} id={this.state.selector + "scanNonSkuPackedQty"} />
                                </label>
                            </div>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton label={this.translate("Label_Cancel")} wmsSelector={this.state.selector + "Cancel"} onClickFunc={this.closeAddNonSkuQuantityModal} disabled={false} loading={false} buttonType="orange" icon={"fa-ban"} />
                            <WmsButton label={this.translate("Label_Next")} wmsSelector={this.state.selector + "Next"} onClickFunc={this.nonSkuQtyNext} disabled={this.state.nonSkuItemQuantity < 0} loading={false} buttonType="green" icon={"fa-caret-right"} />
                        </div>
                    </WmsModel>
                )}
            </div>
        );
    }
}
